import * as _ from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { Row, Col, Card, CardColumns, Input } from 'reactstrap'

import type { DailyWorkRow, ProductivityWorker } from 'api/reports'

import { colorTypeToCode } from 'components/Dashboard/utils'
import WorkerFilter from 'components/Workers/WorkerFilter'
import { BadgeButton, Chart, List } from 'components/common'
import type { BadgeItem, Series, FilterItem } from 'components/common/types'
import { createLineChartOptions, ColorTypes, append, isFilteredWorker } from 'components/common/utils'

import { createXAxis } from './utils'

import styles from './ProductivityWorkerGraph.module.scss'

export type GraphProductivityWorker = ProductivityWorker & {
  workspaceId: number
  workspaceName: string
  groupId: number
  groupName: string
}

export const TabType = {
  Current: 'current',
  Other: 'other',
} as const
export type WorkersType = (typeof TabType)[keyof typeof TabType]

type ProductivityBadgeItem = BadgeItem & {
  unit: string
}

type Props = {
  graphProductivityWorkers: GraphProductivityWorker[]
  start: Date
  end: Date
  type: WorkersType
}

const ProductivityWorkerGraph: React.FC<Props> = props => {
  const { graphProductivityWorkers, start, end, type } = props
  const [selectedId, setSelectedId] = React.useState<number | undefined>(undefined)
  const [selectedBadgeKeys, setSelectedBadgeKeys] = React.useState<number[]>([])
  const [filterWord, setFilterWord] = React.useState('')
  const [workspaceFilterItems, setWorkspaceFilterItems] = React.useState<FilterItem[]>([])

  const listItems = React.useMemo(() => {
    const visibleWorkspaceIds = workspaceFilterItems.filter(item => item.checked).map(item => item.key)
    const items = graphProductivityWorkers
      .filter(
        worker =>
          visibleWorkspaceIds.includes(type === TabType.Current ? worker.groupId : worker.workspaceId) &&
          isFilteredWorker(filterWord, {
            name: worker.workerName,
            wmsMemberId: worker.workerWmsMemberId,
            workerType: worker.workerType,
          })
      )
      .map(worker => ({
        id: worker.workerId,
        title: worker.workerName,
        data: worker.groupName !== '' ? `${worker.workspaceName}/${worker.groupName}` : worker.workspaceName,
      }))
    return _.sortBy(items, 'title')
  }, [graphProductivityWorkers, filterWord, workspaceFilterItems, type])

  React.useEffect(() => {
    const filterItems = graphProductivityWorkers.map(worker => ({
      key: type === TabType.Current ? worker.groupId : worker.workspaceId,
      label: type === TabType.Current ? worker.groupName : worker.workspaceName,
      checked: true,
    }))
    setWorkspaceFilterItems(_.chain(filterItems).uniqBy('key').sortBy('key').value())
  }, [graphProductivityWorkers, type])

  React.useEffect(() => {
    if (listItems.find(item => item.id === selectedId)) {
      return
    }
    setSelectedId(listItems[0]?.id)
  }, [listItems, selectedId])

  const targetWorker = React.useMemo(
    () => graphProductivityWorkers.find(worker => worker.workerId === selectedId),
    [graphProductivityWorkers, selectedId]
  )

  const badgeItems = React.useMemo(() => {
    if (!targetWorker) {
      return []
    }
    const items = targetWorker.dailyWorkData.map<ProductivityBadgeItem>(dailyWork => ({
      color: dailyWork.scheduleTypeColor,
      key: dailyWork.scheduleTypeId,
      label: dailyWork.scheduleTypeName,
      unit: dailyWork.unit,
    }))
    return _.chain(items).uniqBy('key').sortBy('key').value()
  }, [targetWorker])

  React.useEffect(() => {
    // badgeItemsが更新されたら全選択
    setSelectedBadgeKeys(badgeItems.map(badgeItem => badgeItem.key))
  }, [badgeItems])

  const chartOptions = React.useMemo(() => {
    if (!targetWorker) {
      return {}
    }

    const selectedBadges = badgeItems.filter(badge => selectedBadgeKeys.includes(badge.key))
    const xAxisData = createXAxis(start, end)

    const yAxis: Series[] = []
    selectedBadges.forEach(graphBadge => {
      const colorCode = colorTypeToCode(graphBadge.color)

      const dailyWorkRows = targetWorker.dailyWorkData
        .filter(dailyWork => dailyWork.scheduleTypeId === graphBadge.key)
        .flatMap(dailyWork => dailyWork.data.map(row => row))

      const summaryRows = dailyWorkRows.reduce<DailyWorkRow[]>((acc, cur) => {
        const target = acc.find(row => row.date === cur.date)
        if (!target) {
          return acc.concat([cur])
        }
        const newRow = { ...target }
        newRow.dailyAvgPlanCount = append(newRow.dailyAvgPlanCount, cur.dailyAvgPlanCount)
        newRow.dailyAvgRecordCount = append(newRow.dailyAvgRecordCount, cur.dailyAvgRecordCount)
        return acc.map(row => (row.date === cur.date ? newRow : row))
      }, [])

      // areaの上にlineを表示するためにareaを先にしておく
      const recordData = xAxisData.map(date => {
        // パフォーマンス改善のために日付を直接文字列として比較する
        const target = summaryRows.find(row => row.date === date)
        return target ? target.dailyAvgRecordCount : null
      })
      yAxis.push({
        type: 'area',
        color: colorCode,
        data: recordData,
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
        },
      })

      const planData = xAxisData.map(date => {
        // パフォーマンス改善のために日付を直接文字列として比較する
        const target = summaryRows.find(row => row.date === date)
        return target ? target.dailyAvgPlanCount : null
      })
      yAxis.push({
        type: 'line',
        color: colorCode,
        data: planData,
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
        },
      })
    })

    const options = createLineChartOptions(
      {
        xAxis: {
          data: xAxisData,
        },
        yAxis,
      },
      'day'
    )
    _.merge<Highcharts.Options, Highcharts.Options>(options, {
      tooltip: {
        formatter() {
          return (
            '<div style="text-align:center">' +
            [
              moment(this.x).format('YYYY/MM/DD'),
              this.series.name,
              `${Math.floor(this.y || 0)}${this.series.options.custom!.unit}`,
            ].join('<br>') +
            '</div>'
          )
        },
      },
      xAxis: {
        tickInterval: Math.ceil(xAxisData.length / 7),
      },
    })
    return options
  }, [targetWorker, badgeItems, selectedBadgeKeys, start, end])

  return (
    <Row>
      <Col md={4}>
        <div className="d-flex mb-3">
          <Input placeholder="メンバー名もしくは識別子で検索" onChange={e => setFilterWord(e.target.value)} />
          <div className="ms-2">
            <WorkerFilter
              filterItems={workspaceFilterItems}
              onChange={filterItems => setWorkspaceFilterItems(filterItems)}
            />
          </div>
        </div>
        <div className={styles.list}>
          <List items={listItems} selectedId={selectedId} onAction={setSelectedId} />
        </div>
      </Col>
      <Col md={8}>
        {selectedId && (
          <>
            <Chart options={chartOptions} />
            <div className="d-flex row mx-2 mt-4">
              <BadgeButton
                items={badgeItems}
                selected={selectedBadgeKeys}
                onChange={selected => setSelectedBadgeKeys(selected)}
              />
            </div>
            <hr color={ColorTypes.Silver} />
            <div className="font-middle fw-bold py-2">
              {targetWorker?.workerName || ''} さんの生産性（日別）の指定期間の平均
            </div>
            {targetWorker &&
              targetWorker.dailyWorkData.map(dailyWork => (
                <Card key={dailyWork.scheduleTypeId} className="bg-light-gray my-2">
                  <CardColumns className="py-2 px-3 d-flex justify-content-between align-items-center">
                    <span>{dailyWork.scheduleTypeName}</span>
                    <span className="w-25 text-end">
                      {dailyWork.hourlyAvgProductivity !== null
                        ? Math.floor(dailyWork.hourlyAvgProductivity).toLocaleString()
                        : '-'}
                      {dailyWork.unit} /1時間
                    </span>
                  </CardColumns>
                </Card>
              ))}
          </>
        )}
      </Col>
    </Row>
  )
}

export default ProductivityWorkerGraph
