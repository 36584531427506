import { useCallback, useMemo, useState } from 'react'

import type { WorkspaceSummaryData } from 'api/dashboard'

import { NO_GROUP_ID } from 'components/TeamSelect/TeamSelectWorkers'
import { TimeScale, WorkerIcon } from 'components/common'
import TableScale from 'components/common/AssignedNumberTable/TableScale'
import type { TimeReducerType, ColorType } from 'components/common/types'
import { isSupportedWorkerGroup, timeDataReducer } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'
import { useGroupPerformance } from 'hooks/useGroupPerformance'

import styles from './TeamProgressTable.module.scss'

export type Props = {
  groupId: number
  groupName: string
  groupColor: ColorType
  workspaceSummaryData: WorkspaceSummaryData
}

export const TeamProgressTable = ({ groupId, groupName, groupColor, workspaceSummaryData }: Props) => {
  const [openGroup, setOpenGroup] = useState(true)
  const { getWorkerDataList } = useGroupPerformance()

  const { businessHourBlocks } = useBusinessTime()

  const workerDataList = useMemo(() => {
    const targetGroup = workspaceSummaryData?.groups.find(
      group =>
        (groupId === NO_GROUP_ID ? group.groupId === null : group.groupId === groupId) &&
        !isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName)
    )
    if (!targetGroup) {
      return []
    }
    return getWorkerDataList(targetGroup)
  }, [workspaceSummaryData, groupId, getWorkerDataList])

  const groupData: { [key: string]: TimeReducerType } = useMemo(
    () => workerDataList.flatMap(d => Object.values(d.workerData)).reduce(timeDataReducer, {}),
    [workerDataList]
  )
  const groupCounts = useMemo(
    () =>
      businessHourBlocks.reduce((acc, cur) => {
        const rate = groupData[`${cur}:00`]?.rate || null
        const warning = rate !== null && Math.abs(100 - rate) >= 10
        acc.set(cur, <div className={warning ? 'text-danger' : ''}>{rate ? `${rate}%` : ''}</div>)
        return acc
      }, new Map()),
    [businessHourBlocks, groupData]
  )
  const workerCounts = useCallback(
    (workerData: { [key: string]: TimeReducerType }) =>
      businessHourBlocks.reduce((acc, cur) => {
        const rate = workerData[`${cur}:00`]?.rate || null
        const warning = rate !== null && Math.abs(100 - rate) >= 10
        acc.set(cur, <div className={warning ? 'text-danger' : ''}>{rate ? `${rate}%` : ''}</div>)
        return acc
      }, new Map()),
    [businessHourBlocks]
  )

  return (
    <table>
      <thead>
        <tr className={styles.header}>
          <th className={styles.nameHeader}>名前</th>
          <TimeScale round />
        </tr>
      </thead>

      <tbody>
        <tr className={styles.groupRow}>
          <td className={styles.groupLabel}>
            <div className="ps-2 d-flex align-items-center" role="button" onClick={() => setOpenGroup(!openGroup)}>
              <i className={`icf-carot_${openGroup ? 'down' : 'right'} me-1`} />
              <div className="text-truncate">{groupName}</div>
            </div>
          </td>

          <TableScale items={groupCounts} />
        </tr>

        {openGroup &&
          workerDataList.map(worker => (
            <tr key={worker.workerId} className={styles.workerRow}>
              <td className={styles.workerLabel}>
                <div className="px-4 py-2 d-flex align-items-center">
                  <WorkerIcon name={worker.workerName} groupColor={groupColor} />
                  <div className="ms-2 text-truncate fw-normal">{worker.workerName}</div>
                </div>
              </td>
              <td>
                <TableScale items={workerCounts(worker.workerData)} />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}
