import dayjs from 'dayjs'
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CardBody, Row, Col, Input } from 'reactstrap'

import type { WorkspaceSummaryData } from 'api/dashboard'

import { selectTenantsStatus } from 'slices/tenantsSlice'

import WorkerFilter from 'components/Workers/WorkerFilter'
import { List, BadgeButton, Chart } from 'components/common'
import { createLineChartOptions } from 'components/common/Chart/Chart'
import type { BadgeItem, Series, FilterItem } from 'components/common/types'
import { isFilteredWorker, isSupportedWorkerGroup } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'
import { useChart } from 'hooks/useChart'
import useDateQuery from 'hooks/useDateQuery'

import { createXAxis, colorTypeToCode, NULL_GROUP_ID, NULL_GROUP_NAME, SUPPORT_WORKER_GROUP_PREFIX } from './utils'

import styles from './WorkerPerformanceGraph.module.scss'

import type { XAxisOptions } from 'highcharts'

type WorkerPerformanceBadgeItem = BadgeItem & {
  unit: string
}

export type Props = {
  workspaceId: number
  workerId: number | undefined
  workspaceSummaryData: WorkspaceSummaryData | undefined
}

const WorkerPerformanceGraph: React.FC<Props> = ({ workspaceId, workerId, workspaceSummaryData }) => {
  // apiはTotalSummaryで呼び出す
  const { tenantWithDate } = useSelector(selectTenantsStatus, shallowEqual)
  const { getTimeOver24h, businessStartTime, businessEndTime } = useBusinessTime(tenantWithDate)
  const [selectedId, setSelectedId] = React.useState<number | undefined>(undefined)
  const [selectedBadgeKeys, setSelectedBadgeKeys] = React.useState<number[]>([])
  const [filterWord, setFilterWord] = React.useState('')
  const [groupFilterItems, setGroupFilterItems] = React.useState<FilterItem<number | string>[]>([])
  const navigate = useNavigate()
  const date = useDateQuery()
  const { tooltipFormatterWithNextTime } = useChart()

  const listItems = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return []
    }
    const { workspaceName } = workspaceSummaryData
    const visibleGroupIds = groupFilterItems.filter(item => item.checked).map(item => item.key)
    return workspaceSummaryData.groups
      .filter(group =>
        visibleGroupIds.includes(
          group.groupId ||
            (isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName)
              ? `${SUPPORT_WORKER_GROUP_PREFIX}${group.supportedWorkspaceId}`
              : NULL_GROUP_ID)
        )
      )
      .flatMap(group =>
        group.workers
          .filter(worker =>
            isFilteredWorker(filterWord, {
              name: worker.workerName,
              wmsMemberId: worker.workerWmsMemberId,
              workerType: worker.workerType,
            })
          )
          .map(worker => {
            if (isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName)) {
              return {
                id: worker.workerId,
                title: worker.workerName,
                wmsMemberId: worker.workerWmsMemberId,
                data: group.supportedWorkspaceName ?? '',
              }
            }
            return {
              id: worker.workerId,
              title: worker.workerName,
              wmsMemberId: worker.workerWmsMemberId,
              data: `${workspaceName} / ${group.groupName ?? NULL_GROUP_NAME}`,
            }
          })
      )
      .sort((a, b) => a.wmsMemberId.localeCompare(b.wmsMemberId, 'ja'))
  }, [workspaceSummaryData, filterWord, groupFilterItems])

  React.useEffect(() => {
    setSelectedId(workerId ? workerId : listItems[0]?.id)
  }, [workerId, listItems])

  const handleListAction = (id: number) => {
    navigate(`/dashboard/${workspaceId}/performance-graph/workers/${id}?date=${date}`)
  }

  React.useEffect(() => {
    if (!workspaceSummaryData) {
      setGroupFilterItems([])
      return
    }
    setGroupFilterItems(
      workspaceSummaryData.groups.map(group => {
        if (isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName)) {
          return {
            key: `${SUPPORT_WORKER_GROUP_PREFIX}${group.supportedWorkspaceId ?? ''}`,
            label: group.supportedWorkspaceName ?? '',
            checked: true,
          }
        }
        return {
          key: group.groupId ?? NULL_GROUP_ID,
          label: group.groupName ?? NULL_GROUP_NAME,
          checked: true,
        }
      })
    )
  }, [workspaceSummaryData])

  const onSearchClick = (filterItems: FilterItem<number | string>[]) => {
    setGroupFilterItems(filterItems)
  }

  const graphBadges = useMemo(() => {
    if (!selectedId || !workspaceSummaryData) {
      return []
    }
    const badges = workspaceSummaryData.groups.flatMap(group =>
      group.workers
        .filter(worker => worker.workerId === selectedId)
        .flatMap(worker =>
          worker.hourlyWorkerData.map<WorkerPerformanceBadgeItem>(data => ({
            color: data.scheduleTypeColor,
            key: data.scheduleTypeId,
            label: data.scheduleTypeName,
            unit: data.unit,
          }))
        )
    )
    // graphBadgesが更新されたら初回はbadgeを全て選択状態にする
    setSelectedBadgeKeys(badges.map(b => b.key))
    return badges
  }, [selectedId, workspaceSummaryData])

  const chartOptions = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return {}
    }

    const selectedBadges = graphBadges.filter(badge => selectedBadgeKeys.findIndex(key => key === badge.key) !== -1)
    const xAxisData = createXAxis(businessStartTime, businessEndTime, false, date)

    const yAxis: Series[] = []
    selectedBadges.forEach(graphBadge => {
      const colorCode = colorTypeToCode(graphBadge.color)

      const hourlyWorkerRows = workspaceSummaryData.groups.flatMap(group =>
        group.workers
          .filter(worker => worker.workerId === selectedId)
          .flatMap(worker => worker.hourlyWorkerData)
          .filter(hourlyData => hourlyData.scheduleTypeId === graphBadge.key)
          .flatMap(hourlyData => hourlyData.data)
      )

      // areaの上にlineを表示するためにareaを先にしておく
      const recordData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        const target = hourlyWorkerRows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
        return target ? target.recordCount : null
      })
      yAxis.push({
        type: 'area',
        color: colorCode,
        data: recordData,
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
        },
      })

      const planData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        const target = hourlyWorkerRows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
        return target ? target.planCount : null
      })
      const firstDataIndex = planData.findIndex(d => d !== null)
      yAxis.push({
        type: 'line',
        color: colorCode,
        data: planData,
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
          firstDataIndex,
        },
      })
    })

    const options = createLineChartOptions({
      xAxis: {
        data: xAxisData,
      },
      yAxis,
    })
    ;(options.xAxis as XAxisOptions).labels!.formatter = function () {
      if (this.isFirst) {
        return dayjs(this.value).format('HH:mm')
      }
      return getTimeOver24h(dayjs(this.value).format('HH:mm'))
    }
    _.merge<Highcharts.Options, Highcharts.Options>(options, {
      tooltip: {
        formatter: tooltipFormatterWithNextTime,
      },
      xAxis: {
        labels: {
          step: 2,
        },
        tickInterval: 1,
      },
    })
    return options
  }, [
    workspaceSummaryData,
    graphBadges,
    businessStartTime,
    businessEndTime,
    date,
    tooltipFormatterWithNextTime,
    selectedBadgeKeys,
    selectedId,
    getTimeOver24h,
  ])

  return (
    <Row>
      <Col md={4}>
        <div className="d-flex mb-3">
          <Input placeholder="メンバー名もしくは識別子で検索" onChange={e => setFilterWord(e.target.value)} />
          <div className="ms-2">
            <WorkerFilter filterItems={groupFilterItems} onChange={onSearchClick} />
          </div>
        </div>
        <div className={styles.list}>
          <List items={listItems} selectedId={selectedId} onAction={handleListAction} />
        </div>
      </Col>
      <Col md={8}>
        <Chart options={chartOptions} />

        <CardBody className="d-flex row my-2">
          <BadgeButton
            items={graphBadges}
            selected={selectedBadgeKeys}
            onChange={(list: number[]) => setSelectedBadgeKeys(list)}
          />
        </CardBody>
      </Col>
    </Row>
  )
}

export default WorkerPerformanceGraph
