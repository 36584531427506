import { createSlice } from '@reduxjs/toolkit'
import { sortBy, partition } from 'es-toolkit'

import { makeErrorMessage, UNREACHABLE_ERROR_STATUS_CODE, UNAUTHORIZED_ERROR_STATUS_CODE } from 'api/utils'
import * as API from 'api/workers'

import * as NetworkErrorDialog from 'slices/networkErrorDialogSlice'
import { validateToken } from 'slices/sessionSlice'
import * as SessionTimeoutDialog from 'slices/sessionTimeoutDialogSlice'
import * as Spinner from 'slices/spinnerSlice'
import { commonParams } from 'slices/utils'

import type { PayloadAction } from '@reduxjs/toolkit'
import type { AxiosError } from 'axios'
import type { AppThunk, RootState } from 'store'

export type CreateUpdateParam = {
  createData: API.WorkerScheduleUpdateType[]
  updateData: Array<{ data: API.WorkerScheduleUpdateType; scheduleId: number }>
}

type WorkersState = {
  isRequesting: boolean
  errorMessage: string
  partialWorkers: API.PartialWorkerData[]
  worker?: API.WorkerData
  downloadUrl: string | undefined
}

const initialState: WorkersState = {
  isRequesting: false,
  errorMessage: '',
  partialWorkers: [],
  worker: undefined,
  downloadUrl: undefined,
}

export const workersSlice = createSlice({
  name: 'workers',
  initialState,
  reducers: {
    startRequest: state => {
      state.isRequesting = true
      state.errorMessage = ''
    },
    apiFailure: (state, action: PayloadAction<{ errorMessage: string }>) => {
      state.isRequesting = false
      state.errorMessage = action.payload.errorMessage
    },
    getWorkerListSuccess: (state, action: PayloadAction<API.WorkerListResponse>) => {
      state.isRequesting = false
      const [regularWorkers, spotWorkers] = partition(
        action.payload.partialWorkers,
        worker => worker.workerType === API.WorkerTypes.RegularMember
      )

      const sortedRegularWorkers = sortBy(regularWorkers, ['wmsMemberId'])
      const sortedSpotWorkers = sortBy(spotWorkers, ['id'])
      state.partialWorkers = sortedRegularWorkers.concat(sortedSpotWorkers)
    },
    getWorkerSuccess: (state, action: PayloadAction<API.WorkerResponse>) => {
      state.isRequesting = false
      state.worker = action.payload.worker
    },

    requestSuccess: state => {
      state.isRequesting = false
    },
    getExportDataUrlSuccess: (state, action: PayloadAction<API.CheckExportDataReadyResponse>) => {
      state.isRequesting = false
      state.downloadUrl = action.payload.downloadUrl
    },
    resetExportDataUrl: state => (state.downloadUrl = undefined),
  },
})

export const {
  startRequest,
  apiFailure,
  getWorkerListSuccess,
  getWorkerSuccess,
  requestSuccess,
  getExportDataUrlSuccess,
  resetExportDataUrl,
} = workersSlice.actions

export const getWorkerList =
  (tenantId?: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    const defaultParams = commonParams(getState)
    const params = tenantId ? { ...defaultParams, tenantId } : defaultParams

    API.getWorkerList(params)
      .then((res: API.WorkerListResponse) => dispatch(getWorkerListSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const getWorker =
  (workerId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.getWorker(commonParams(getState), workerId)
      .then((res: API.WorkerResponse) => dispatch(getWorkerSuccess(res)))
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const createWorker =
  (data: API.CreateWorkerEditDataType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.createWorker(commonParams(getState), data)
      .then(res => {
        dispatch(requestSuccess())
        dispatch(getWorkerList())
        dispatch(getWorker(res.id))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const updateWorker =
  (workerId: number, data: API.UpdateWorkerEditDataType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.updateWorker(commonParams(getState), workerId, data)
      .then(() => {
        dispatch(requestSuccess())
        dispatch(getWorkerList())
        dispatch(getWorker(workerId))
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const deleteWorker =
  (workerId: number): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())

    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    API.deleteWorker(commonParams(getState), workerId)
      .then(() => {
        dispatch(requestSuccess())
        dispatch(getWorkerList())
      })
      .catch((res: AxiosError) => {
        const errorCode = makeErrorMessage(res)
        if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
          dispatch(SessionTimeoutDialog.open())
        } else if (errorCode === UNREACHABLE_ERROR_STATUS_CODE) {
          dispatch(NetworkErrorDialog.open({ code: errorCode }))
        }
        dispatch(apiFailure({ errorMessage: errorCode }))
      })
      .finally(() => dispatch(Spinner.stop()))
  }

export const importWorkers =
  (fileName: string, workerType: API.WorkerType, csvContent: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    try {
      const response = await API.workersUploadUrl(commonParams(getState), fileName, workerType)
      await API.putUploadUrl(response.uploadUrl, csvContent)
      dispatch(requestSuccess())
    } catch (error) {
      const errorCode = makeErrorMessage(error as AxiosError)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const exportWorkers =
  (data: API.ExportWorkersDataType): AppThunk =>
  async (dispatch, getState) => {
    dispatch(startRequest())
    const valid = await dispatch(validateToken())
    if (!valid) {
      return
    }

    dispatch(Spinner.start())
    try {
      const exportDataResponse = await API.exportWorkers(commonParams(getState), data)
      if (exportDataResponse?.downloadUrl) {
        return dispatch(getExportDataUrlSuccess(exportDataResponse))
      }
    } catch (err) {
      const errorCode = makeErrorMessage(err as AxiosError)
      if (errorCode === UNAUTHORIZED_ERROR_STATUS_CODE) {
        dispatch(SessionTimeoutDialog.open())
      } else {
        dispatch(NetworkErrorDialog.open({ code: errorCode }))
      }
      dispatch(apiFailure({ errorMessage: errorCode }))
    } finally {
      dispatch(Spinner.stop())
    }
  }

export const selectWorkersStatus = (state: RootState) => ({ ...state.workers })

export default workersSlice.reducer
