import * as React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

import { getBopProfitAndLoss } from 'slices/bopSlice'
import { getTenantSummary } from 'slices/dashboardSlice'
import { selectSessionStatus } from 'slices/sessionSlice'
import { getWorkspaceList } from 'slices/workspacesSlice'

import { Pivot, PivotItem } from 'components/common'

import useBusinessTime from 'hooks/useBusinessTime'
import useDateChange from 'hooks/useDateChange'
import useDateQuery from 'hooks/useDateQuery'

import BopMonitoringProfitAndLoss from './BopMonitoringProfitAndLoss'
import BopMonitoringSummary from './BopMonitoringSummary'
import Dashboard from './Dashboard'

const BopMonitoring: React.FC = () => {
  const { user } = useSelector(selectSessionStatus, shallowEqual)
  const dispatch = useDispatch()
  const { getWorkDate } = useBusinessTime()
  const date = getWorkDate(useDateQuery())
  const { checkTenantCreated } = useDateChange()

  // ダッシュボード収支モニタリング取得
  React.useEffect(() => {
    dispatch(getBopProfitAndLoss(date))
    dispatch(getTenantSummary(date, { dashboardFilter: true }))
  }, [dispatch, date])

  React.useEffect(() => {
    dispatch(getWorkspaceList({ workDate: date, tenantId: user.userHasTenants[0].id }))
  }, [dispatch, user, date])

  const handleDateChange = React.useCallback((newDate: string) => checkTenantCreated(newDate), [checkTenantCreated])

  return (
    <Dashboard onInterval={() => dispatch(getBopProfitAndLoss(date))} onDateChange={handleDateChange}>
      <Pivot>
        {[
          { header: 'サマリー', key: 'summary' },
          { header: '損益詳細', key: 'profit_and_loss_detail' },
        ].map(({ header, key }) => (
          <PivotItem headerText={header} key={key}>
            {key === 'summary' ? (
              <BopMonitoringSummary />
            ) : key === 'profit_and_loss_detail' ? (
              <BopMonitoringProfitAndLoss />
            ) : null}
          </PivotItem>
        ))}
      </Pivot>
    </Dashboard>
  )
}

export default BopMonitoring
