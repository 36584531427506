import dayjs from 'dayjs'
import { sortBy } from 'es-toolkit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import { SPOT_WORKER_SHIFT_STATUS } from 'api/spot_workers/constants'
import type { SpotWorkerColumnDataType } from 'api/spot_workers/types'

import { selectSpotWorkerStatus } from 'slices/spotWorkerSlice'

import type { FilterStatusType } from 'components/common/SpotWorkerTable/utils'
import {
  FILTER_STATUS_LABEL,
  filterStatusList,
  isValidTimeContinuity,
  isValidTimeSelection,
  UNREGISTERED_VALUE_ID,
} from 'components/common/SpotWorkerTable/utils'
import type { FilterItem } from 'components/common/types'

import useBusinessTime from './useBusinessTime'

const EDITABLE_PAST_DATE_RANGE = 32

export type editDataKeyTypes =
  | 'workspace'
  | 'group'
  | 'workStart1'
  | 'workEnd1'
  | 'workStart2'
  | 'workEnd2'
  | 'workStart3'
  | 'workEnd3'
  | 'wmsMemberId'
  | 'template'
  | 'skills'

export const useSpotWorker = () => {
  const { spotWorkers, partialWorkspacesAndSubMasters, skills } = useSelector(selectSpotWorkerStatus, shallowEqual)
  const [tableData, setTableData] = useState<SpotWorkerColumnDataType[]>([])
  const [initTableData, setInitTableData] = useState<SpotWorkerColumnDataType[]>([])
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<number[]>([])
  const [selectedSkills, setSelectedSkills] = useState<number[]>([])
  const [selectedStatus, setSelectedStatus] = useState<FilterStatusType[]>([])
  const [filterWorkerName, setFilterWorkerName] = useState<string>('')

  const { getWorkDate } = useBusinessTime()

  const checkSpotWorkerShiftStatusType = (value: number): value is FilterStatusType => {
    return Object.values(FILTER_STATUS_LABEL).includes(value as FilterStatusType)
  }

  const checkIsDateInEditableRange = useCallback(
    (date: string) => {
      const targetDate = dayjs(date)
      const date32DaysAgo = dayjs(getWorkDate(dayjs().format('YYYY-MM-DD'))).subtract(EDITABLE_PAST_DATE_RANGE, 'day')
      return targetDate.isAfter(date32DaysAgo)
    },
    [getWorkDate]
  )

  const targetWorkspaces = useMemo(
    () =>
      partialWorkspacesAndSubMasters.map(workspace => ({
        id: workspace.workspaceId,
        name: workspace.workspaceName,
      })),
    [partialWorkspacesAndSubMasters]
  )

  const targetGroups = useMemo(
    () =>
      partialWorkspacesAndSubMasters.flatMap(({ workspaceId, partialGroups }) =>
        partialGroups.map(({ groupId, groupName }) => ({ workspaceId, id: groupId, name: groupName }))
      ),
    [partialWorkspacesAndSubMasters]
  )

  const targetTemplates = useMemo(
    () =>
      partialWorkspacesAndSubMasters.flatMap(({ workspaceId, partialTemplate }) =>
        partialTemplate.map(({ templateId, templateName }) => ({ workspaceId, id: templateId, name: templateName }))
      ),
    [partialWorkspacesAndSubMasters]
  )

  useEffect(() => {
    const data = spotWorkers.map(spotWorker => {
      const targetWorkspace = targetWorkspaces.find(w => w.id === spotWorker.workspaceId)
      const targetGroup = targetGroups.find(
        g => g.workspaceId === spotWorker.workspaceId && g.id === spotWorker.groupId
      )
      const targetTemplate = targetTemplates.find(t => t.id === spotWorker.workTemplateId)
      const targetSkills = spotWorker.skillIds
        ? spotWorker.skillIds.map(skillId => ({
            id: skillId,
            name: skills.find(skill => skill.id === skillId)?.name || '',
          }))
        : []

      return {
        revision: spotWorker.revision,
        lineNumber: spotWorker.lineNumber,
        name: spotWorker.workerName,
        status: spotWorker.status,
        deletion: spotWorker.deletion,
        sickout: spotWorker.sickout,
        workerId: spotWorker.workerId,
        workspace: targetWorkspace ? { id: targetWorkspace.id, name: targetWorkspace.name } : null,
        group: targetGroup ? { id: targetGroup.id, name: targetGroup.name } : null,
        workStart1: spotWorker.workStart1,
        workEnd1: spotWorker.workEnd1,
        workStart2: spotWorker.workStart2,
        workEnd2: spotWorker.workEnd2,
        workStart3: spotWorker.workStart3,
        workEnd3: spotWorker.workEnd3,
        wmsMemberId: spotWorker.wmsMemberId,
        template: targetTemplate ? { id: targetTemplate.id, name: targetTemplate.name } : null,
        skills: targetSkills,
        isUpdatedByUser: spotWorker.isUpdatedColumn,
      }
    })
    const sortedSickoutData = sortBy(
      data.filter(d => d.status === SPOT_WORKER_SHIFT_STATUS.SICK_OUT),
      ['workerId']
    )
    const sortedNewData = sortBy(
      data.filter(d => d.status === SPOT_WORKER_SHIFT_STATUS.INSUFFICIENT),
      ['lineNumber']
    )
    const sortedOtherData = sortBy(
      data.filter(
        d => d.status !== SPOT_WORKER_SHIFT_STATUS.SICK_OUT && d.status !== SPOT_WORKER_SHIFT_STATUS.INSUFFICIENT
      ),
      ['workerId']
    )
    const sortedTableData = [...sortedOtherData, ...sortedNewData, ...sortedSickoutData]

    setInitTableData(prev =>
      sortedTableData.map(d => {
        const prevData = prev.find(pd => pd.lineNumber === d.lineNumber)
        return d.isUpdatedByUser ? d : prevData ? prevData : d
      })
    )
    setTableData(prev =>
      sortedTableData.map(d => {
        const prevData = prev.find(pd => pd.lineNumber === d.lineNumber)
        return d.isUpdatedByUser ? d : prevData ? prevData : d
      })
    )
  }, [spotWorkers, targetWorkspaces, targetGroups, targetTemplates, skills])

  const filterWorkspaces: FilterItem<number>[] = useMemo(
    () =>
      partialWorkspacesAndSubMasters.map(workspace => ({
        key: workspace.workspaceId,
        label: workspace.workspaceName,
        checked: selectedWorkspaces.includes(workspace.workspaceId),
      })),
    [partialWorkspacesAndSubMasters, selectedWorkspaces]
  )

  const filterSkills: FilterItem<number>[] = useMemo(
    () =>
      skills.map(skill => ({
        key: skill.id,
        label: skill.name,
        checked: selectedSkills.includes(skill.id),
      })),
    [selectedSkills, skills]
  )

  const filterStatus: FilterItem<number>[] = useMemo(
    () =>
      filterStatusList.map(status => ({
        ...status,
        checked: selectedStatus.includes(status.key),
      })),
    [selectedStatus]
  )

  // フィルターの結果を反映した表データ
  const filteredTableData = useMemo(() => {
    const statusIncludes = {
      UnregisteredWmsMemberId: selectedStatus.includes(FILTER_STATUS_LABEL.UNREGISTERED_WMS_MEMBER_ID),
      RegisteredWmsMemberId: selectedStatus.includes(FILTER_STATUS_LABEL.REGISTERED_WMS_MEMBER_ID),
      New: selectedStatus.includes(FILTER_STATUS_LABEL.NEW),
      ShiftUnregistered: selectedStatus.includes(SPOT_WORKER_SHIFT_STATUS.SHIFT_UNREGISTERED),
      ShiftRegistered: selectedStatus.includes(SPOT_WORKER_SHIFT_STATUS.SHIFT_REGISTERED),
      UnUpdated: selectedStatus.includes(SPOT_WORKER_SHIFT_STATUS.NON_UPDATED),
    }

    return tableData.filter(data => {
      // メンバー名で絞り込み
      const matchesName = filterWorkerName === '' || data.name.includes(filterWorkerName)

      // ワークスペースとスキルで絞り込み
      const matchesWorkspaceAndSkills =
        (selectedWorkspaces.length === 0 || selectedWorkspaces.includes(data.workspace?.id || UNREGISTERED_VALUE_ID)) &&
        (selectedSkills.length === 0 || data.skills.some(skill => selectedSkills.includes(skill.id)))

      // その他で絞り込み
      const matchesStatus =
        selectedStatus.length === 0 ||
        (statusIncludes.UnregisteredWmsMemberId && !data.wmsMemberId) ||
        (statusIncludes.RegisteredWmsMemberId && !!data.wmsMemberId) ||
        (statusIncludes.New && !data.workerId) ||
        (statusIncludes.ShiftUnregistered && data.status === SPOT_WORKER_SHIFT_STATUS.SHIFT_UNREGISTERED) ||
        (statusIncludes.ShiftRegistered && data.status === SPOT_WORKER_SHIFT_STATUS.SHIFT_REGISTERED) ||
        (statusIncludes.UnUpdated && data.status === SPOT_WORKER_SHIFT_STATUS.NON_UPDATED)

      return matchesName && matchesWorkspaceAndSkills && matchesStatus
    })
  }, [filterWorkerName, selectedSkills, selectedStatus, selectedWorkspaces, tableData])

  const checkValidColumn = useCallback((columnData: SpotWorkerColumnDataType) => {
    const status = columnData.status
    // シフト登録済、未更新のバリデーション
    if (status === SPOT_WORKER_SHIFT_STATUS.SHIFT_REGISTERED || status === SPOT_WORKER_SHIFT_STATUS.NON_UPDATED) {
      return columnData.workspace !== null
    }

    // 情報不足（メンバーID未確定） or 情報不足（メンバーID確定）のバリデーション or シフト未登録のバリデーション
    if (
      status === SPOT_WORKER_SHIFT_STATUS.INSUFFICIENT ||
      status === SPOT_WORKER_SHIFT_STATUS.INSUFFICIENT_WITHOUT_ID ||
      status === SPOT_WORKER_SHIFT_STATUS.SHIFT_UNREGISTERED
    ) {
      // 勤務時間n-1が設定されていないのに、勤務時間nが設定されている場合はエラー
      const isSetTime1 = columnData.workStart1 && columnData.workEnd1
      const isSetTime2 = columnData.workStart2 && columnData.workEnd2
      const isSetTime3 = columnData.workStart3 && columnData.workEnd3

      const isValidSetWorkTime2 = !isSetTime1 ? !isSetTime2 : true
      const isValidSetWorkTime3 = !isSetTime2 ? !isSetTime3 : true
      columnData.workStart2 && columnData.workEnd2 ? columnData.workStart3 && columnData.workEnd3 : true

      // 開始時間が終了時間より前であることを確認
      const isValidWorkTimeSelection1 = isValidTimeSelection(columnData.workStart1, columnData.workEnd1)
      const isValidWorkTimeSelection2 = isValidTimeSelection(columnData.workStart2, columnData.workEnd2)
      const isValidWorkTimeSelection3 = isValidTimeSelection(columnData.workStart3, columnData.workEnd3)

      // 勤務時間の連続性を確認
      const isValidContinuityWorkTime1 =
        isValidWorkTimeSelection1 && isValidWorkTimeSelection2
          ? isValidTimeContinuity(columnData.workEnd1, columnData.workStart2)
          : true
      const isValidContinuityWorkTime2 =
        isValidWorkTimeSelection2 && isValidWorkTimeSelection3
          ? isValidTimeContinuity(columnData.workEnd2, columnData.workStart3)
          : true
      return (
        isValidSetWorkTime2 &&
        isValidSetWorkTime3 &&
        isValidWorkTimeSelection1 &&
        isValidWorkTimeSelection2 &&
        isValidWorkTimeSelection3 &&
        isValidContinuityWorkTime1 &&
        isValidContinuityWorkTime2
      )
    }
    return true
  }, [])

  return {
    checkSpotWorkerShiftStatusType,
    checkIsDateInEditableRange,
    tableData,
    filteredTableData,
    initTableData,
    setTableData,
    targetWorkspaces,
    targetGroups,
    targetTemplates,
    selectedWorkspaces,
    selectedSkills,
    selectedStatus,
    setSelectedWorkspaces,
    setSelectedSkills,
    setSelectedStatus,
    setFilterWorkerName,
    filterWorkspaces,
    filterSkills,
    filterStatus,
    filterWorkerName,
    checkValidColumn,
  }
}

export default useSpotWorker
