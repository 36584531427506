import dayjs from 'dayjs'
import { compact, chain, uniq, sortBy } from 'lodash'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Popup from 'reactjs-popup'

import type { TenantData } from 'api/tenants'

import { selectScheduleTypesStatus } from 'slices/scheduleTypesSlice'

import SupportConfirm from 'components/SupportConfirm/SupportConfirm'
import { TableCheckbox, TimeScale } from 'components/common'
import {
  getShiftBarWidthByDuration,
  UNSELECTED_SCHEDULE_TYPE_ID,
  SHIFT_SCHEDULE_TYPE_ID,
  SUPPORT_SCHEDULE_TYPE_ID,
  getRandomNumber,
} from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'
import usePlans from 'hooks/usePlans'

import { AssignToWorkTableWorkerRow } from './AssignToWorkTableWorkerRow'
import WorkPlanPopover from './WorkPlanPopover'
import { AssignToWorkTableContext } from './context'

import styles from './AssignToWorkTable.module.scss'

import type { SelectItem } from './WorkPlanPopover'
import type { WorkPlanSchedulesType, EditGroupsWorkerType, EditGroupsType } from '../types'
import type { Moment } from 'moment'
import type { Dispatch, RefObject, SetStateAction } from 'react'

/*
 * このコンポーネントの呼び出し元である WorkPlan.tsx で実行しているため
 * このコンポーネントでは getWorkspaceList, getScheduleTypeList, getWork を呼び出す必要はない
 */

export type SelectedWorkerType = {
  groupId: number | null
  workerIds: number[]
}

type Props = {
  workspaceId: number
  date: string
  isPast: boolean
  editGroups: EditGroupsType[]
  setEditGroups: (items: EditGroupsType[]) => void
  selectedWorker: number[]
  setSelectedWorker: Dispatch<SetStateAction<number[]>>
  divElement: RefObject<HTMLDivElement>
  onScroll: () => void
  className?: string
  tenantWithDate?: TenantData
}
const AssignToWorkTable = (props: Props) => {
  const {
    workspaceId,
    date,
    isPast,
    editGroups,
    setEditGroups,
    selectedWorker,
    setSelectedWorker,
    divElement,
    onScroll,
    className,
    tenantWithDate,
  } = props

  const { shiftKeyDown, selectedSchedules, setSelectedSchedules } = useContext(AssignToWorkTableContext)

  const [openSupportConfirm, setOpenSupportConfirm] = useState(false)
  const [name, setName] = useState('')
  const [start, setStart] = useState('')
  const [end, setEnd] = useState('')
  const [openGroups, setOpenGroups] = useState<string[]>([])
  const [onApprove, setOnApprove] = useState<() => void>(() => {})
  const { pathname } = useLocation()

  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)

  const { getShiftBarXbyStartTime, getTimesByShiftBarX, businessStartTime, businessDuration, getTimeOver24h } =
    useBusinessTime(tenantWithDate)
  const { planWorkDate } = usePlans()
  const selectedWorkerIncludes = (workerId: number | undefined) => {
    return typeof workerId === 'number' && selectedWorker.includes(workerId)
  }
  const selectedScheduleIncludes = (scheduleId: number | null) => {
    return selectedSchedules.some(s => s.scheduleId === scheduleId)
  }

  const workerActiveRange = (worker: EditGroupsWorkerType) => {
    const shifts = chain(worker.schedules)
      .filter({ scheduleTypeId: SHIFT_SCHEDULE_TYPE_ID })
      .sortBy(['startAt'])
      .value()
    if (shifts.length === 0) {
      return undefined
    }
    const range: Array<[number, number]> = shifts.map(s => {
      const shiftStart = getShiftBarXbyStartTime(s.startAt, planWorkDate)
      const shiftEnd = getShiftBarWidthByDuration(s.duration) + shiftStart - 1
      return [shiftStart, shiftEnd]
    })
    return range
  }

  useEffect(() => {
    setOpenGroups([])
    setSelectedWorker([])
  }, [pathname, setSelectedWorker])

  const handleGroupNameClick = (group: string) => {
    const newOpenGroup = openGroups.includes(group)
      ? openGroups.filter(openGroupName => openGroupName !== group)
      : openGroups.concat([group])
    setOpenGroups(newOpenGroup)
  }
  const isOpen = (group: string) => openGroups.includes(group)

  const onSelectWorkerScheduleType = (
    scheduleId: number | null,
    workerId: number,
    scheduleTypeId: number,
    supportWorkspaceId: number | null,
    supportWorkspaceName: string | null
  ) => {
    const isMultipleSchedule = selectedScheduleIncludes(scheduleId)
    const newScheduleIds: Array<number | null> = []
    const newEditGroups = editGroups.map(group => {
      // group.supportedWorkspaceName がある場合、他のワークスペースグループと判定できる
      const isSupport = group.supportedWorkspaceName && supportWorkspaceId !== null

      const workers = group.workers.map(w => ({
        ...w,
        schedules: w.schedules.reduce<WorkPlanSchedulesType[]>((acc, cur) => {
          // [未選択] の場合は scheduleTypeId が 0 になっている
          const isFirstChose = cur.scheduleTypeId === UNSELECTED_SCHEDULE_TYPE_ID
          const isSameWorker = w.workerId === workerId || selectedWorkerIncludes(w.workerId) || isMultipleSchedule
          const isSameScheduleId =
            cur.scheduleId === scheduleId || (isMultipleSchedule && selectedScheduleIncludes(cur.scheduleId))
          if (isSameWorker && isSameScheduleId) {
            // ワークスペースの作業員の場合はそのまま変更可能
            if (!isSupport) {
              const newScheduleId = !scheduleId || isFirstChose ? getRandomNumber() : cur.scheduleId
              newScheduleIds.push(newScheduleId)
              acc.push({
                ...cur,
                scheduleId: newScheduleId,
                scheduleTypeId,
                supportWorkspaceId,
                supportWorkspaceName,
                isGroup: false,
              })
            } else if (!isFirstChose) {
              // 応援の作業員で[未選択]以外の場合はスケジュール変更なし
              newScheduleIds.push(cur.scheduleId)
              acc.push(cur)
            }
          } else {
            acc.push(cur)
          }
          return acc
        }, []),
      }))
      return {
        ...group,
        workers,
      }
    })
    // scheduleId が変わった場合に予定の選択状態が変わらないようにする
    const newSelectedSchedules = compact(newScheduleIds).map(newScheduleId => ({
      scheduleId: newScheduleId,
      time: selectedSchedules.find(s => s.scheduleId === scheduleId)?.time ?? '',
    }))
    setSelectedSchedules(newSelectedSchedules)
    setEditGroups(newEditGroups)
  }

  const handleDeleteWorkerScheduleType = (
    scheduleId: number | null,
    workerId: number,
    isMultipleWorkers: boolean,
    isMultipleSchedule: boolean
  ) => {
    const newEditGroups = editGroups.map(group => {
      const workers = group.workers.map(w => ({
        ...w,
        schedules: w.schedules.filter(s =>
          s.scheduleId !== scheduleId || isMultipleSchedule
            ? !selectedScheduleIncludes(s.scheduleId)
            : isMultipleWorkers && w.workerId !== workerId && !selectedWorkerIncludes(w.workerId)
        ),
      }))
      return {
        ...group,
        workers,
      }
    })

    setSelectedSchedules([])
    setEditGroups(newEditGroups)
  }

  const handleSelectScheduleType = (
    item: SelectItem,
    scheduleId: number | null,
    startAt: string,
    duration: number,
    workerId: number
  ) => {
    const isSupport = !item?.color
    const scheduleTypeId = isSupport ? SUPPORT_SCHEDULE_TYPE_ID : item.id
    const supportWorkspaceId = isSupport ? item.id : null
    const supportWorkspaceName = (isSupport && item.name) || null
    setName(item?.name || '')

    const startTime = dayjs(startAt).local().format('HH:mm')
    const endTime = dayjs(startAt).local().add(duration, 'seconds').format('HH:mm')
    setStart(getTimeOver24h(startTime, true))
    setEnd(getTimeOver24h(endTime))

    handleOpenSupportConfirm(() => {
      onSelectWorkerScheduleType(scheduleId, workerId, scheduleTypeId, supportWorkspaceId, supportWorkspaceName)
    }, isSupport)
  }

  const handleOpenSupportConfirm = (func: () => void, isSupport: boolean) => {
    setOnApprove(() => func)
    if (isSupport) {
      setOpenSupportConfirm(true)
    } else {
      func()
    }
  }

  const groupWorkerSchedule = (
    schedules: WorkPlanSchedulesType[],
    startAt: string,
    duration: number,
    scheduleId?: number | null
  ) => {
    // 開始時刻か終了時刻が含まれるシフトを入力可能域にする
    // 入力可能域がない場合はundefinedを返す
    const endAt = moment(startAt).add(duration, 'seconds').format()
    // シフト時間
    const baseSchedule = schedules
      .filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID)
      .reduce(
        (acc, cur) => {
          const curEndAt = moment(cur.startAt).add(cur.duration, 'seconds')
          if (
            acc.startAt === '' &&
            ((moment(startAt).isBefore(cur.startAt, 'minute') && moment(endAt).isAfter(curEndAt, 'minute')) ||
              moment(startAt).isBetween(cur.startAt, curEndAt, 'minute', '[)') ||
              moment(endAt).isBetween(cur.startAt, curEndAt, 'minute', '(]'))
          ) {
            return { startAt: cur.startAt, duration: cur.duration }
          }
          return acc
        },
        { startAt: '', duration: 0 }
      )
    if (baseSchedule.startAt === '') {
      return undefined
    }

    // そのほかの作業が入力可能域にあった場合、作業入力可能域を狭める
    const schedule = schedules
      .filter(s => s.scheduleId !== scheduleId && s.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID)
      .reduce(
        (acc: { startAt: string; duration: number }, cur) => {
          const accEndAt = moment(acc.startAt).add(acc.duration, 'seconds').format()
          const curEndAt = moment(cur.startAt).add(cur.duration, 'seconds').format()

          // シフト時間外の作業は無視
          if (!moment(cur.startAt).isBetween(acc.startAt, accEndAt, 'minute', '[]')) {
            return acc
          }
          if (moment(startAt).isBefore(cur.startAt, 'minute') && moment(cur.startAt).isAfter(acc.startAt, 'minute')) {
            return {
              startAt: acc.startAt,
              duration: moment(cur.startAt).unix() - moment(acc.startAt).unix(),
            }
          }
          if (moment(curEndAt).isBefore(accEndAt, 'minute')) {
            return {
              startAt: curEndAt,
              duration: moment(accEndAt).unix() - moment(curEndAt).unix(),
            }
          }
          return { startAt: '', duration: 0 }
        },
        { startAt: baseSchedule.startAt, duration: baseSchedule.duration }
      )

    if (schedule.startAt === '') {
      return undefined
    }

    // 基準域の終了時間
    const shiftEndAt = moment(schedule.startAt).add(schedule.duration, 'seconds').format()

    // 開始位置・終了位置が基準開始前の時
    if (
      moment(startAt).isBefore(schedule.startAt, 'minute') &&
      moment(endAt).isSameOrBefore(schedule.startAt, 'minute')
    ) {
      return undefined
    }

    // 開始位置・終了位置が基準域外の時
    if (moment(startAt).isBefore(schedule.startAt, 'minute') && moment(endAt).isAfter(shiftEndAt, 'minute')) {
      return {
        startAt: schedule.startAt,
        duration: schedule.duration,
      }
    }

    // 開始位置が基準域外の時
    if (moment(startAt).isBefore(schedule.startAt) && moment(endAt).isSameOrBefore(shiftEndAt)) {
      return {
        startAt: schedule.startAt,
        duration: duration - (moment(schedule.startAt).unix() - moment(startAt).unix()),
      }
    }

    // 終了位置が基準域外の時
    if (moment(startAt).isSameOrAfter(schedule.startAt) && moment(endAt).isAfter(shiftEndAt)) {
      return { startAt, duration: duration - (moment(endAt).unix() - moment(shiftEndAt).unix()) }
    }

    // 全て基準域に収まる場合
    return { startAt, duration }
  }

  const handleShiftBarAdd = (groupId: number | null, startPos: number, endPos: number, workerId: number) => {
    if (!groupId && !workerId) {
      return
    }
    // シフトキーを押している時は新しい作業は追加させない
    if (shiftKeyDown) {
      return
    }
    // 新しい作業を追加した時は選択済みの作業を全解除
    setSelectedSchedules([])

    const isMultiple = selectedWorkerIncludes(workerId)
    const time = getTimesByShiftBarX(startPos)
    const startAt = dayjs(`${date} ${time.hours}:${time.minutes}`).utc().format()
    const addItem: WorkPlanSchedulesType = {
      scheduleId: getRandomNumber(),
      scheduleTypeId: UNSELECTED_SCHEDULE_TYPE_ID,
      supportWorkspaceId: null,
      supportWorkspaceName: null,
      startAt,
      duration: (endPos - startPos) * 900,
      editable: true,
    }

    const newEditGroups = editGroups.map(group => {
      if (!isMultiple && group.groupId !== groupId) {
        return group
      }
      return {
        ...group,
        workers: group.workers.map(w => {
          const schedule = groupWorkerSchedule(w.schedules, startAt, addItem.duration)
          const getSchedules = () => {
            if (w.workerId === workerId) {
              return [...w.schedules, addItem]
            }
            if (isMultiple && schedule && selectedWorkerIncludes(w.workerId)) {
              return [
                ...w.schedules,
                { ...addItem, isGroup: true, startAt: schedule.startAt, duration: schedule.duration },
              ]
            }
            return w.schedules
          }
          return {
            ...w,
            schedules: getSchedules(),
          }
        }),
      }
    })

    setEditGroups(newEditGroups)
  }

  const getMultipleWorkerEditGroup = (
    workerId: number,
    index: number,
    startAt: string,
    duration: number,
    currentSchedule: WorkPlanSchedulesType
  ) => {
    return editGroups.map(group => {
      const workers = group.workers.map(w => {
        const schedule = groupWorkerSchedule(w.schedules, startAt, duration, currentSchedule.scheduleId)
        const schedules = w.schedules
          .filter(s => s.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID)
          .reduce((acc: WorkPlanSchedulesType[], cur, idx) => {
            if (w.workerId === workerId && idx === index) {
              acc.push({ ...cur, startAt, duration })
            } else if (cur.scheduleId === currentSchedule.scheduleId && schedule) {
              acc.push({ ...cur, startAt: schedule.startAt, duration: schedule.duration })
            } else if (cur.scheduleId !== currentSchedule.scheduleId || schedule) {
              acc.push(cur)
            }
            return acc
          }, [])
          .concat(w.schedules.filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID))

        if (
          schedules.every(s => s.scheduleId !== currentSchedule.scheduleId) &&
          schedule &&
          selectedWorkerIncludes(w.workerId)
        ) {
          schedules.push({ ...currentSchedule, isGroup: true, startAt: schedule.startAt, duration: schedule.duration })
        }

        return { ...w, schedules }
      })
      return {
        ...group,
        workers,
      }
    })
  }

  const getSingleWorkerEditGroup = (
    groupId: number | null,
    workerId: number,
    index: number,
    startAt: string,
    duration: number
  ) => {
    return editGroups.map(group => {
      if (group.groupId !== groupId) {
        return group
      }

      const workers = group.workers.map(w => {
        const shifts = w.schedules.filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID)
        const schedulesWithoutShifts = w.schedules
          .filter(s => s.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID)
          .map((s, idx) => {
            if (w.workerId === workerId && idx === index) {
              return { ...s, scheduleId: s.scheduleId, startAt, duration }
            }
            return s
          })
        return {
          ...w,
          schedules: shifts.concat(schedulesWithoutShifts),
        }
      })

      return {
        ...group,
        workers,
      }
    })
  }

  const getStartAtDuration = (
    movedObject: boolean,
    changeStartAt: Moment,
    changeDuration: number,
    workerSchedules: WorkPlanSchedulesType[],
    currentStartAt: string
  ) => {
    const shifts = workerSchedules.filter(s => s.scheduleTypeId === SHIFT_SCHEDULE_TYPE_ID)
    const baseShift = shifts.find(s => {
      // 移動した時のシフト位置の取得は移動後の値から取得
      const baseStartAt = movedObject ? changeStartAt : currentStartAt
      return (
        moment(s.startAt).isSameOrBefore(baseStartAt) &&
        moment(s.startAt).add(s.duration, 'seconds').isAfter(baseStartAt)
      )
    })
    // 変更後の作業がシフト内に収まってない場合は元のシフトが基準になるようにする(movedObject=trueの場合)
    const currentShift = shifts.find(s => {
      return (
        moment(s.startAt).isSameOrBefore(currentStartAt) &&
        moment(s.startAt).add(s.duration, 'seconds').isAfter(currentStartAt)
      )
    })

    // それでも見つけられない場合は開始時間が一番近いシフトを基準にする
    const nearShifts = shifts.sort(
      (a, b) => Math.abs(changeStartAt.diff(a.startAt)) - Math.abs(changeStartAt.diff(b.startAt))
    )

    const shift = baseShift || currentShift || nearShifts[0]

    const shiftEndAt = moment(shift.startAt).add(shift.duration, 'seconds')
    const changeEndAt = changeStartAt.clone().add(changeDuration, 'seconds')
    if (changeEndAt.isSameOrBefore(shift.startAt)) {
      return { startAt: shift.startAt, duration: 900 }
    } else if (changeStartAt.isBefore(shift.startAt)) {
      return { startAt: shift.startAt, duration: Math.abs(changeEndAt.diff(shift.startAt, 'seconds')) }
    } else if (changeStartAt.isSameOrAfter(shiftEndAt)) {
      return { startAt: shiftEndAt.subtract(900, 'seconds').utc().format(), duration: 900 }
    } else if (changeEndAt.isAfter(shiftEndAt)) {
      return {
        startAt: changeStartAt.utc().format(),
        duration: changeDuration - changeEndAt.diff(shiftEndAt, 'seconds'),
      }
    }
    return { startAt: changeStartAt.utc().format(), duration: changeDuration }
  }

  const getMultipleScheduleEditGroup = (startAt: string, duration: number, currentSchedule: WorkPlanSchedulesType) => {
    // 左端／右端／オブジェクト移動の判定
    const isSameStartAt = moment(startAt).isSame(currentSchedule.startAt)
    const isSameEndAt = moment(startAt)
      .add(duration, 'seconds')
      .isSame(moment(currentSchedule.startAt).add(currentSchedule.duration, 'seconds'))
    const changeLeft = !isSameStartAt && isSameEndAt
    const changeRight = isSameStartAt && !isSameEndAt
    const movedObject = !changeLeft && !changeRight

    const diffDuration = duration - currentSchedule.duration
    const diffSeconds = moment(startAt).diff(currentSchedule.startAt, 'seconds')

    return editGroups.map(group => {
      const workers = group.workers.map(w => ({
        ...w,
        schedules: w.schedules.reduce<WorkPlanSchedulesType[]>((acc, cur) => {
          if (selectedScheduleIncludes(cur.scheduleId)) {
            const changeDuration = Math.max(cur.duration + diffDuration, 900)
            const changeStartAt = movedObject
              ? moment(cur.startAt).add(diffSeconds, 'seconds')
              : changeLeft
                ? cur.duration + diffDuration <= 0
                  ? moment(cur.startAt).add(cur.duration - 900, 'seconds')
                  : moment(cur.startAt).subtract(diffDuration, 'seconds')
                : moment(cur.startAt)

            const startAtDuration = getStartAtDuration(
              movedObject,
              changeStartAt,
              changeDuration,
              w.schedules,
              cur.startAt
            )

            if (movedObject || changeLeft) {
              acc.push({ ...cur, startAt: startAtDuration.startAt, duration: startAtDuration.duration })
            } else if (changeRight) {
              acc.push({ ...cur, duration: startAtDuration.duration })
            } else {
              acc.push(cur)
            }
          } else {
            acc.push(cur)
          }
          return acc
        }, []),
      }))
      return {
        ...group,
        workers,
      }
    })
  }

  const handleWorkerShiftBarChange = (
    groupId: number | null,
    workerId: number,
    index: number,
    x: number,
    width: number
  ) => {
    const time = getTimesByShiftBarX(x)
    const startAt = dayjs(`${date} ${time.hours}:${time.minutes}`).utc().format()
    const duration = width * 900
    const currentSchedule = editGroups
      .find(g => g.groupId === groupId)
      ?.workers.find(w => w.workerId === workerId)
      ?.schedules.filter(s => s.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID)?.[index]

    // チェックボックスに入ってる作業者の場合
    const isMultipleWorker =
      selectedWorkerIncludes(workerId) && currentSchedule?.scheduleTypeId === UNSELECTED_SCHEDULE_TYPE_ID
    // 複数選択したスケジュールの場合(1件の場合は含まない)
    const isMultipleSchedule =
      selectedScheduleIncludes(currentSchedule?.scheduleId ?? null) && selectedSchedules.length > 1

    const newEditGroups =
      isMultipleSchedule && currentSchedule
        ? getMultipleScheduleEditGroup(startAt, duration, currentSchedule)
        : isMultipleWorker && currentSchedule
          ? getMultipleWorkerEditGroup(workerId, index, startAt, duration, currentSchedule)
          : getSingleWorkerEditGroup(groupId, workerId, index, startAt, duration)

    !isMultipleSchedule && setSelectedSchedules([{ scheduleId: currentSchedule?.scheduleId ?? 0, time: '' }])
    setEditGroups(newEditGroups)
  }

  const getShiftBarItems = (
    groupId: number | null,
    schedules: WorkPlanSchedulesType[],
    supportedWorkspaceId: number | null,
    workerId: number
  ) => {
    return schedules
      .filter(
        d =>
          d.scheduleTypeId !== SHIFT_SCHEDULE_TYPE_ID &&
          (partialScheduleTypes.some(p => p.id === d.scheduleTypeId) ||
            d.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID ||
            d.scheduleTypeId === UNSELECTED_SCHEDULE_TYPE_ID)
      )
      .map((d, index) => {
        const isSupport = d.scheduleTypeId === SUPPORT_SCHEDULE_TYPE_ID
        const schedule = isSupport
          ? { id: d.supportWorkspaceId ?? 0, name: d.supportWorkspaceName ?? undefined, color: undefined }
          : partialScheduleTypes.reduce(
              (acc: SelectItem, cur) => {
                if (cur.id === d.scheduleTypeId) {
                  return { id: cur.id, name: cur.name, color: cur.color }
                }
                return acc
              },
              { id: UNSELECTED_SCHEDULE_TYPE_ID }
            )

        const x = getShiftBarXbyStartTime(d.startAt, planWorkDate)
        const width = getShiftBarWidthByDuration(d.duration)
        const selected = { id: schedule.id, name: schedule.name, color: schedule.color }
        const isMultipleWorker = selectedWorkerIncludes(workerId)
        const isMultipleSchedule = selectedScheduleIncludes(d.scheduleId)
        return {
          id: `item-${groupId}-${index}`,
          content: (
            <WorkPlanPopover
              workspaceId={workspaceId}
              scheduleId={d.scheduleId || 0}
              selected={selected}
              viewWorkspace={!supportedWorkspaceId || isMultipleWorker || isMultipleSchedule}
              startTime={d.startAt}
              duration={d.duration}
              isGroup={!!d?.isGroup}
              disabled={isPast || !d.editable}
              onSelect={item => handleSelectScheduleType(item, d.scheduleId, d.startAt, d.duration, workerId)}
              onDelete={() =>
                handleDeleteWorkerScheduleType(d.scheduleId, workerId, isMultipleWorker, isMultipleSchedule)
              }
            />
          ),
          x,
          width,
          color: schedule?.color,
          invertedColor: isMultipleSchedule,
          disabled: !d.editable,
        }
      })
  }

  const getWorkerHeaderClass = (workerId: number) => {
    if (selectedWorkerIncludes(workerId)) {
      return `${styles.selectedWorkerTableHeader} ${styles.workerName}`
    }
    return `${styles.tableHeader} ${styles.workerName}`
  }

  const getWorkerContentClass = (workerId: number) => {
    if (selectedWorkerIncludes(workerId)) {
      return `${styles.selectedWorkerTableContent}`
    }
    return `${styles.tableContent}`
  }

  const onClickWorkerHandler = (workerId: number) => {
    if (selectedWorkerIncludes(workerId)) {
      setSelectedWorker(selectedWorker.filter(id => id !== workerId))
    } else {
      setSelectedWorker([...selectedWorker, workerId])
    }
  }

  const groupFound = (groupId: number | null, groupName: string) =>
    editGroups.find(group => group.groupId === groupId && group.name === groupName)

  const onClickGroupHandler = (groupId: number | null, groupName: string, checked: boolean) => {
    const workerIds = groupFound(groupId, groupName)?.workers?.map(w => w.workerId) ?? []
    if (checked) {
      setSelectedWorker(uniq([...selectedWorker, ...workerIds]))
      setOpenGroups(uniq([...openGroups, groupName]))
    } else {
      setSelectedWorker(selectedWorker.filter(workerId => !workerIds.includes(workerId)))
    }
  }

  // 選択された作業者にフィルタリングを適用する
  useEffect(() => {
    const visibleWorkerIds = editGroups
      .flatMap(g => g.workers)
      .filter(w => w.visible)
      .map(w => w.workerId)

    if (selectedWorker.every(sw => visibleWorkerIds.includes(sw))) {
      return
    }

    setSelectedWorker(prev => prev.filter(sw => visibleWorkerIds.includes(sw)))
  }, [selectedWorker, editGroups, setSelectedWorker])

  const getGroupIndeterminate = (groupId: number | null, groupName: string) => {
    const found = groupFound(groupId, groupName)?.workers
    const filtered = found?.filter(worker => selectedWorkerIncludes(worker.workerId)) ?? []
    return found && filtered.length > 0 && filtered.length !== found.length
  }

  const getGroupChecked = (groupId: number | null, groupName: string) => {
    const workerIds = groupFound(groupId, groupName)?.workers?.map(w => w.workerId) ?? []
    return workerIds.some(workerId => selectedWorkerIncludes(workerId))
  }

  const allWorkerIds = useMemo(
    () => editGroups.flatMap(group => group.workers.map(worker => worker.workerId)),
    [editGroups]
  )
  const onClickAllHandler = (checked: boolean) => {
    if (checked) {
      setSelectedWorker(allWorkerIds)
      setOpenGroups(editGroups.map(group => group.name))
    } else {
      setSelectedWorker([])
    }
  }
  const allIndeterminate = useMemo(
    () => selectedWorker.length > 0 && selectedWorker.length !== allWorkerIds.length,
    [allWorkerIds, selectedWorker]
  )

  return (
    <>
      <div ref={divElement} className={`${styles.tableWrapper} ${className}`} onScroll={onScroll}>
        <table>
          <thead>
            <tr className={styles.timeHeader}>
              <td className={`bg-secondary-pale px-2 ${styles.tableHeader}`}>
                <div className="d-flex align-items-center">
                  {!isPast && (
                    <TableCheckbox
                      id="assign-to-work-table-check-all"
                      indeterminate={allIndeterminate}
                      checked={selectedWorker.length > 0}
                      onClick={onClickAllHandler}
                    />
                  )}
                  <span className="ps-3">グループ</span>
                </div>
              </td>
              <td className="p-0">
                <TimeScale tenantWithDate={tenantWithDate} />
              </td>
            </tr>
          </thead>
          {editGroups.map((g, index) => (
            <tbody key={`group-${g.groupId}-${index}`}>
              <tr className={styles.tableRow}>
                <td className={styles.groupHeader} role="button">
                  <div className="d-flex align-items-center ps-2">
                    {!isPast && (
                      <TableCheckbox
                        id={`assign-to-work-table-check-group-${g.groupId}`}
                        indeterminate={getGroupIndeterminate(g.groupId, g.name)}
                        checked={getGroupChecked(g.groupId, g.name)}
                        onClick={checked => onClickGroupHandler(g.groupId, g.name, checked)}
                      />
                    )}
                    <Popup
                      trigger={
                        <div className="d-flex align-items-center px-2" onClick={() => handleGroupNameClick(g.name)}>
                          <i className={`icf-carot_${isOpen(g.name) ? 'down' : 'right'} me-1`} />
                          <div className={`${styles.groupName} text-truncate`}>{g.name}</div>
                        </div>
                      }
                      position="bottom center"
                      on="hover"
                      arrowStyle={{ color: 'black' }}
                    >
                      <span className="text-white bg-black p-1 px-3 rounded font-small">
                        {g.name}:{g.workers.filter(w => w.visible).length}人
                      </span>
                    </Popup>
                  </div>
                </td>
              </tr>
              {isOpen(g.name) &&
                sortBy(g.workers, 'wmsMemberId')
                  .filter(worker => worker.visible)
                  .map(worker => (
                    <AssignToWorkTableWorkerRow
                      worker={worker}
                      businessStartTime={businessStartTime}
                      shiftBarWidth={businessDuration}
                      shiftBarActiveRange={workerActiveRange(worker)}
                      shiftBarItems={getShiftBarItems(
                        g.groupId,
                        worker.schedules,
                        g.supportedWorkspaceId,
                        worker.workerId
                      )}
                      shiftBarDisabled={isPast}
                      checkBoxVisible={!isPast}
                      shiftBarClassName={getWorkerContentClass(worker.workerId)}
                      headerClassName={getWorkerHeaderClass(worker.workerId)}
                      onShiftBarAdd={(startPos, endPos) =>
                        handleShiftBarAdd(g.groupId, startPos, endPos, worker.workerId)
                      }
                      onShiftBarChange={(idx, x, width) =>
                        handleWorkerShiftBarChange(g.groupId, worker.workerId, idx, x, width)
                      }
                      onCheckBoxClick={() => onClickWorkerHandler(worker.workerId)}
                      checkboxChecked={selectedWorkerIncludes(worker.workerId)}
                      key={`worker-${worker.workerId}`}
                    />
                  ))}
            </tbody>
          ))}
        </table>
      </div>

      <SupportConfirm
        isOpen={openSupportConfirm}
        start={start}
        end={end}
        name={name}
        onCancel={() => setOpenSupportConfirm(false)}
        onApprove={() => {
          onApprove?.()
          setOpenSupportConfirm(false)
        }}
      />
    </>
  )
}

export default AssignToWorkTable
