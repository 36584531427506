import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col, Button, Card, CardBody, CardTitle, CardText, CardColumns } from 'reactstrap'

import type { ScheduleTypeData, ScheduleTypeEditData } from 'api/schedule_types'
import type { SkillData } from 'api/skills'
import { CONFLICT_ERROR_STATUS_CODE, ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import { showError, showSuccess } from 'slices/notificationSlice'
import {
  getScheduleTypeList,
  getScheduleType,
  selectScheduleTypesStatus,
  updateScheduleType,
  createScheduleType,
} from 'slices/scheduleTypesSlice'
import { getSkillList, selectSkillsStatus } from 'slices/skillsSlice'
import { getDataConnectionInfo } from 'slices/tenantsSlice'
import { getWorkspace, selectWorkspacesStatus } from 'slices/workspacesSlice'

import {
  NavMenu,
  List,
  InputGroupFormat,
  InputFormat,
  SelectBoxFormat,
  ColorPickerFormat,
  CardSubmitFooter,
  CustomButton,
  ItemEdit,
  BadgeLabel,
  CheckBoxFormat,
} from 'components/common'
import * as Rules from 'components/common/FormFormat/ValidationRules'
import type { SuggestionItem, ListItem, ConnectionType } from 'components/common/types'
import { getConnectionTypeLabel, ColorTypes, connectionTypes, ColumnSizes } from 'components/common/utils'

import useAuthority from 'hooks/useAuthority'
import useScheduleType from 'hooks/useScheduleType'

import placeholder from 'images/allEmpty.svg'

import ConnectionTypeButton from './ConnectionTypeButton'
import FilterEdit from './FilterEdit'
import ScheduleTypeDelete from './ScheduleTypeDelete'

import styles from './WorkspaceDetail.module.scss'

const onDetailClick = () => window.open('https://help.smileboard.jp/setup_work', '_blank')

const NEW_SCHEDULE_TYPE_ID = 0

type EnhancedScheduleType = ScheduleTypeData & {
  requiredSkills: SkillData[]
}

const WorkspaceDetail: React.FC = () => {
  const params = useParams<'workspaceId'>()
  const workspaceId = Number(params.workspaceId)

  const [initEditData, setInitEditData] = React.useState<ScheduleTypeEditData | undefined>()
  const [selectedScheduleTypeId, setSelectedScheduleTypeId] = React.useState<number | undefined>(undefined)
  const [scheduleTypeListItems, setScheduleTypeListItems] = React.useState<ListItem[]>([])
  const [submitted, setSubmitted] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)

  const { workspace } = useSelector(selectWorkspacesStatus, shallowEqual)
  const { skills } = useSelector(selectSkillsStatus, shallowEqual)
  const { partialScheduleTypes, scheduleType, scheduleTypeWorkspaceId, isRequesting, errorMessage } = useSelector(
    selectScheduleTypesStatus,
    shallowEqual
  )

  const hasScheduleTypeListItems = React.useMemo(() => !_.isEmpty(scheduleTypeListItems), [scheduleTypeListItems])

  const dispatch = useDispatch()

  const isNewItem = React.useMemo(() => selectedScheduleTypeId === NEW_SCHEDULE_TYPE_ID, [selectedScheduleTypeId])

  const {
    setNameValidity,
    setTargetDatabaseValidity,
    setTargetColumnValidity,
    setUnitValidity,
    setDefaultProductivityIndexValidity,
    disabled,
    editData,
    setEditData,
    scheduleTypeUpdateData,
    targetDatabases,
    targetColumns,
    filterColumns,
    initScheduleTypeEditData,
  } = useScheduleType()

  const { isReadOnlyWorkspace } = useAuthority(workspaceId)

  React.useEffect(() => {
    dispatch(getScheduleTypeList(workspaceId))
    dispatch(getWorkspace(workspaceId))
  }, [dispatch, workspaceId])

  React.useEffect(() => {
    dispatch(getDataConnectionInfo())
    dispatch(getSkillList())
  }, [dispatch])

  // scheduleType.requiredSkillIdsとマッチするskillsを、requiredSkillsに代入する（idとnameを使用するため）
  const enhancedScheduleType = React.useMemo(() => {
    if (!scheduleType || !skills) {
      return
    }
    return {
      ...scheduleType,
      requiredSkills: skills.filter(skill => scheduleType.requiredSkillIds.includes(skill.id)),
    }
  }, [scheduleType, skills])

  React.useEffect(() => {
    if (selectedScheduleTypeId === NEW_SCHEDULE_TYPE_ID) {
      setEditData(initScheduleTypeEditData)
      return
    }
    if (!selectedScheduleTypeId || !workspaceId) {
      return
    }
    dispatch(getScheduleType(workspaceId, selectedScheduleTypeId))
  }, [workspaceId, selectedScheduleTypeId, dispatch, setEditData, initScheduleTypeEditData])

  React.useEffect(() => {
    // 前WSのpartialScheduleTypesが残っているため、workspaceIdとscheduleTypeWorkspaceIdが不一致の間はsetScheduleTypeListItemsしない
    if (workspaceId !== scheduleTypeWorkspaceId) {
      return
    }
    setScheduleTypeListItems(
      partialScheduleTypes.map(s => {
        return {
          id: s.id,
          title: s.name,
          option: <div className={`${styles.square} bg-${s.color}`} />,
        }
      })
    )
  }, [partialScheduleTypes, workspaceId, scheduleTypeWorkspaceId])

  React.useEffect(() => {
    if (_.isEmpty(scheduleTypeListItems)) {
      return
    }

    setSelectedScheduleTypeId(prev => {
      // 追加アイテムを保存した時
      if (prev === NEW_SCHEDULE_TYPE_ID && !scheduleTypeListItems.some(item => item.id === NEW_SCHEDULE_TYPE_ID)) {
        return Number(_.maxBy(scheduleTypeListItems, 'id')!.id)
      }
      // 初期化時とアイテム削除時
      if (prev === undefined || !scheduleTypeListItems.some(item => item.id === prev)) {
        return Number(scheduleTypeListItems[0].id)
      }
      return prev
    })
  }, [scheduleTypeListItems])

  const updateEditData = React.useCallback(
    (data: EnhancedScheduleType | undefined) => {
      const newEditData = data
        ? {
            name: data.name,
            color: data.color,
            requiredSkills: data.requiredSkills,
            connectionType: data.connectionType,
            dataConnection: data.dataConnection,
            targetDatabase: data.targetDatabase ?? undefined,
            targetColumn: data.targetColumn ?? undefined,
            unit: data.unit ?? undefined,
            filters: data.filters,
            defaultProductivity: data.defaultProductivity?.toString(),
            isKey: data.isKey,
            isCostless: data.isCostless,
          }
        : initScheduleTypeEditData

      setEditData(newEditData)
      setInitEditData(newEditData)
    },
    [setEditData, setInitEditData, initScheduleTypeEditData]
  )

  React.useEffect(() => {
    if (selectedScheduleTypeId === NEW_SCHEDULE_TYPE_ID) {
      setEditData({
        name: undefined,
        color: ColorTypes.Silver,
        requiredSkills: [],
        connectionType: connectionTypes.None,
        dataConnection: false,
        targetDatabase: undefined,
        targetColumn: undefined,
        unit: undefined,
        filters: [],
        isKey: false,
        isCostless: false,
      })
      return
    }
    updateEditData(enhancedScheduleType)
  }, [selectedScheduleTypeId, enhancedScheduleType, updateEditData, setEditData])

  const unchanged = React.useMemo(() => _.isEqual(initEditData, editData), [initEditData, editData])

  const handleItemSelect = React.useCallback((id: number) => {
    setSelectedScheduleTypeId(prevScheduleTypeId => {
      if (prevScheduleTypeId === id) {
        return prevScheduleTypeId
      }
      if (prevScheduleTypeId === NEW_SCHEDULE_TYPE_ID) {
        setScheduleTypeListItems(prev => prev.filter(item => item.id !== NEW_SCHEDULE_TYPE_ID))
      }

      return id
    })
  }, [])

  const onSubmit = () => {
    if (selectedScheduleTypeId === NEW_SCHEDULE_TYPE_ID && scheduleTypeUpdateData) {
      setSubmitted(true)
      scheduleTypeUpdateData && dispatch(createScheduleType(workspaceId, scheduleTypeUpdateData))
      return
    }

    if (!scheduleTypeUpdateData || !scheduleType) {
      return
    }

    setSubmitted(true)
    dispatch(updateScheduleType(workspaceId, scheduleType.id, scheduleTypeUpdateData))
  }

  const onCancel = React.useCallback(() => {
    if (selectedScheduleTypeId === NEW_SCHEDULE_TYPE_ID) {
      setScheduleTypeListItems(prev => prev.filter(item => item.id !== NEW_SCHEDULE_TYPE_ID))
      const newId = !_.isEmpty(partialScheduleTypes) && partialScheduleTypes[0]?.id
      setSelectedScheduleTypeId(newId || undefined)
    }
    updateEditData(enhancedScheduleType)
  }, [partialScheduleTypes, selectedScheduleTypeId, enhancedScheduleType, updateEditData])

  const onAddNewItem = React.useCallback(() => {
    setScheduleTypeListItems(prev =>
      prev.concat({
        title: '',
        id: NEW_SCHEDULE_TYPE_ID,
        option: <div className={`${styles.square} bg-${ColorTypes.Silver}`} />,
      })
    )
    setSelectedScheduleTypeId(NEW_SCHEDULE_TYPE_ID)
  }, [])

  const handleConnectionTypeChange = (value: ConnectionType) => {
    const dataConnection = value !== connectionTypes.None
    editData &&
      setEditData({
        ...editData,
        connectionType: value,
        dataConnection,
        targetDatabase: undefined,
        targetColumn: undefined,
        unit: undefined,
        filters: [],
        defaultProductivity: undefined,
        isKey: false,
      })
  }

  React.useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      dispatch(showSuccess())
      dispatch(getScheduleTypeList(workspaceId))
    } else {
      if (errorMessage === CONFLICT_ERROR_STATUS_CODE) {
        dispatch(showError({ errorMessage: '登録上限に達しているため、保存できませんでした。' }))
      } else if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
        dispatch(showError())
      }
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, dispatch, workspaceId])

  const handleSkillEdit = (items: SuggestionItem[]) => {
    if (!editData) {
      return
    }
    const requiredSkills = skills.filter(s => items.some(i => i.id === s.id))
    setEditData({ ...editData, requiredSkills })
  }

  const handleDelete = () => {
    setOpenDelete(false)
    dispatch(showSuccess())
  }

  return (
    <>
      <NavMenu>
        <div className="mt-3 mx-3">
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="font-x-large flex-grow-1 fw-bold">作業管理</div>
                <div className="px-2">{workspace && <BadgeLabel label={workspace?.name || ''} />}</div>
              </div>
              <CustomButton
                icon="plus"
                disabled={isReadOnlyWorkspace || scheduleTypeListItems.some(item => item.id === NEW_SCHEDULE_TYPE_ID)}
                className="ms-2"
                onClick={onAddNewItem}
              >
                作業の追加
              </CustomButton>
            </div>
          </div>
          <Row className={styles.row}>
            <Col md={4} className="h-100">
              <Card className={`h-100 ${styles.list}`}>
                {hasScheduleTypeListItems ? (
                  <List items={scheduleTypeListItems} selectedId={selectedScheduleTypeId} onAction={handleItemSelect} />
                ) : (
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <img className={`mx-auto d-block w-100 ${styles.placeholderImage}`} src={placeholder} alt="" />
                      <div className="font-middle fw-bold py-4">作業がまだ登録されていません</div>
                      <div>
                        作業を登録することで、メンバーの予定を作ったり、作業毎の生産性を見ることができます。まずは最初の作業を登録してみましょう。
                      </div>
                      <Button className="mx-auto d-block m-4" size="sm" outline onClick={onDetailClick}>
                        作業についてもっと詳しく
                      </Button>
                    </div>
                  </CardBody>
                )}
              </Card>
            </Col>
            <Col md={8} className="h-100">
              <Card className="h-100">
                {hasScheduleTypeListItems ? (
                  <>
                    <div className="h-100 overflow-auto">
                      <CardBody>
                        <CardColumns className="d-flex">
                          <CardTitle className="font-large flex-grow-1 fw-bold">作業の詳細</CardTitle>
                          <span className="font-x-small text-muted">※必須項目</span>
                        </CardColumns>
                        <InputFormat
                          label="名称※"
                          placeholder="作業名を入力"
                          value={editData.name}
                          size={ColumnSizes.middle}
                          maxLength={100}
                          disabled={isReadOnlyWorkspace}
                          onChange={value => setEditData({ ...editData, name: value })}
                          validations={[Rules.Required]}
                          onValidate={setNameValidity}
                          className="mb-3"
                        />
                        <ColorPickerFormat
                          label="キーカラー"
                          color={editData.color}
                          size={ColumnSizes.middle}
                          disabled={isReadOnlyWorkspace}
                          onChange={color => setEditData({ ...editData, color })}
                        />
                      </CardBody>

                      <CardBody>
                        <CardTitle className="font-large fw-bold">必須スキル設定</CardTitle>
                        <CardText className="py-2">
                          作業の必須スキルを設定しておくことで、対象のスキルを持っていないメンバーの予定に作業が入れられた際に
                          アラートを出します。（アラートは出ますが予定に入れることは可能です。）
                        </CardText>
                        <ItemEdit
                          items={skills.map(s => ({ id: s.id, value: s.name }))}
                          selectedItems={editData.requiredSkills.map(s => ({ id: s.id, value: s.name }))}
                          label="作業に必須スキルを追加"
                          itemName="スキル"
                          onChange={handleSkillEdit}
                        />
                      </CardBody>

                      <CardBody className="pb-0">
                        <CardTitle className="font-large fw-bold">実績入力方法</CardTitle>
                        {selectedScheduleTypeId !== NEW_SCHEDULE_TYPE_ID ? (
                          <>
                            <div className="mb-3">
                              <InputFormat
                                label="実績入力方法"
                                onChange={() => {}}
                                disabled
                                size={ColumnSizes.middle}
                                value={getConnectionTypeLabel(editData.connectionType)}
                              />
                            </div>
                            {editData.connectionType !== connectionTypes.None && (
                              <>
                                {editData.connectionType === connectionTypes.Auto && (
                                  <>
                                    <CardText>抽出条件の設定</CardText>
                                    <Card>
                                      <CardBody>
                                        <SelectBoxFormat
                                          className="mb-0"
                                          label="参照するデータベース※"
                                          placeholder="データベースを選択"
                                          value={editData.targetDatabase}
                                          size={ColumnSizes.middle}
                                          items={targetDatabases}
                                          disabled={isReadOnlyWorkspace}
                                          onChange={e =>
                                            setEditData({
                                              ...editData,
                                              targetDatabase: e.key?.toString(),
                                              targetColumn: undefined,
                                              filters: [],
                                            })
                                          }
                                          validations={[Rules.Required]}
                                          onValidate={setTargetDatabaseValidity}
                                        />
                                      </CardBody>
                                      <hr className="m-0" />
                                      <CardBody>
                                        <SelectBoxFormat
                                          label="実績が記載されている列※"
                                          placeholder="列を選択"
                                          value={targetColumns.find(t => t.key === editData.targetColumn)?.key}
                                          size={ColumnSizes.middle}
                                          items={targetColumns}
                                          disabled={isReadOnlyWorkspace}
                                          onChange={e =>
                                            setEditData({
                                              ...editData,
                                              targetColumn: e.key?.toString(),
                                            })
                                          }
                                          validations={[Rules.Required]}
                                          onValidate={setTargetColumnValidity}
                                        />
                                        <InputFormat
                                          className="mt-3"
                                          label="実績の単位※"
                                          placeholder="単位を入力"
                                          value={editData.unit}
                                          size={ColumnSizes.middle}
                                          maxLength={10}
                                          disabled={isReadOnlyWorkspace}
                                          onChange={value => setEditData({ ...editData, unit: value })}
                                          validations={[Rules.Required]}
                                          onValidate={setUnitValidity}
                                        />
                                      </CardBody>

                                      <hr className="m-0"></hr>
                                      <CardBody>
                                        <CardTitle className="font-large fw-bold">フィルター</CardTitle>
                                        <div className="pb-3">
                                          伝票種別などに応じて、より詳細にフィルタリングすることができます。
                                        </div>
                                        <FilterEdit
                                          filterColumns={filterColumns}
                                          filter={editData.filters}
                                          disabled={isReadOnlyWorkspace}
                                          onChange={filters => setEditData({ ...editData, filters })}
                                        />
                                      </CardBody>
                                    </Card>
                                  </>
                                )}
                                {editData.connectionType === connectionTypes.Manual && (
                                  <InputFormat
                                    className="my-3"
                                    label="実績の単位※"
                                    placeholder="単位を入力"
                                    value={editData.unit}
                                    size={ColumnSizes.middle}
                                    maxLength={10}
                                    disabled={isReadOnlyWorkspace}
                                    onChange={value => setEditData({ ...editData, unit: value })}
                                    validations={[Rules.Required]}
                                    onValidate={setUnitValidity}
                                  />
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="mt-2 mb-1">
                              実績をSmile Board Connectに入力する方法を選択してください
                              <div className="text-danger">登録後の実績入力方法の変更はできません。</div>
                            </div>
                            <div className="my-3">
                              <ConnectionTypeButton
                                value={editData?.connectionType || connectionTypes.None}
                                onChange={handleConnectionTypeChange}
                              />
                            </div>
                            {editData?.connectionType !== connectionTypes.None && (
                              <>
                                {editData?.connectionType === connectionTypes.Auto && (
                                  <div>
                                    <div className="mb-2">抽出条件の設定</div>
                                    <Card>
                                      <CardBody>
                                        <SelectBoxFormat
                                          label="参照するデータベース※"
                                          placeholder="データベースを選択"
                                          value={editData.targetDatabase}
                                          size={ColumnSizes.middle}
                                          items={targetDatabases}
                                          onChange={e =>
                                            setEditData({
                                              ...editData,
                                              targetDatabase: e.key?.toString(),
                                              targetColumn: undefined,
                                              filters: [],
                                            })
                                          }
                                          validations={[Rules.Required]}
                                          onValidate={setTargetDatabaseValidity}
                                        />
                                      </CardBody>
                                      <hr className="m-0"></hr>
                                      <CardBody>
                                        <SelectBoxFormat
                                          label="実績が記載されている列※"
                                          placeholder="列を選択"
                                          value={targetColumns.find(t => t.key === editData.targetColumn)?.key}
                                          size={ColumnSizes.middle}
                                          items={targetColumns}
                                          disabled={!editData.targetDatabase}
                                          onChange={e =>
                                            setEditData({
                                              ...editData,
                                              targetColumn: e.key?.toString(),
                                            })
                                          }
                                          validations={editData.targetDatabase ? [Rules.Required] : []}
                                          onValidate={setTargetColumnValidity}
                                        />
                                        <InputFormat
                                          className="mt-3"
                                          label="実績の単位※"
                                          placeholder="単位を入力"
                                          value={editData.unit}
                                          size={ColumnSizes.middle}
                                          maxLength={10}
                                          onChange={value => setEditData({ ...editData, unit: value })}
                                          validations={[Rules.Required]}
                                          onValidate={setUnitValidity}
                                        />
                                      </CardBody>
                                      <hr className="m-0"></hr>
                                      <CardBody>
                                        <CardTitle className="font-large fw-bold">フィルター</CardTitle>
                                        <div className="pb-3">
                                          伝票種別などに応じて、より詳細にフィルタリングすることができます。
                                        </div>
                                        <FilterEdit
                                          filterColumns={filterColumns}
                                          filter={editData?.filters || []}
                                          onChange={filters => setEditData({ ...editData, filters })}
                                        />
                                      </CardBody>
                                    </Card>
                                  </div>
                                )}
                                {editData?.connectionType === connectionTypes.Manual && (
                                  <div>
                                    <InputFormat
                                      label="実績の単位※"
                                      placeholder="単位を入力"
                                      value={editData.unit}
                                      size={ColumnSizes.middle}
                                      maxLength={10}
                                      onChange={value => setEditData({ ...editData, unit: value })}
                                      validations={[Rules.Required]}
                                      onValidate={setUnitValidity}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </CardBody>
                      {editData.connectionType !== connectionTypes.None && (
                        <>
                          <CardBody>
                            <CardTitle className="font-large fw-bold">デフォルトの人時生産性設定</CardTitle>
                            <CardText>
                              この作業の過去実績がない場合に設定されるデフォルトの人時生産性を設定できます。
                              過去実績がなくても作業者管理で各作業者に手動で人時生産性を設定することもできます。
                            </CardText>
                            <InputGroupFormat
                              label="デフォルト値※"
                              addonText={`${editData.unit || '-'}/時間`}
                              value={editData.defaultProductivity}
                              disabled={isReadOnlyWorkspace}
                              maxLength={10}
                              onChange={value =>
                                setEditData({
                                  ...editData,
                                  defaultProductivity: value,
                                })
                              }
                              validations={[Rules.Required, Rules.PositiveInteger]}
                              onValidate={setDefaultProductivityIndexValidity}
                            />
                          </CardBody>
                          <CardBody>
                            <CardTitle className="font-large fw-bold">キー作業設定</CardTitle>
                            <CardText>
                              キー作業に設定すると、同一ワークスペースの他の作業と配賦設定できるようになります。
                            </CardText>
                            <CheckBoxFormat
                              label="キー作業設定"
                              checkboxLabel="この作業をキー作業にする"
                              checked={editData.isKey}
                              disabled={isReadOnlyWorkspace || editData.isCostless}
                              onChange={e => setEditData({ ...editData, isKey: e.target.checked })}
                            />
                          </CardBody>
                        </>
                      )}

                      <CardBody>
                        <CardTitle className="font-large fw-bold">費用外作業設定</CardTitle>
                        <CardText>
                          費用外作業に設定すると、設定された作業の作業時間は費用に換算されません。休憩時間等に設定してください。
                        </CardText>
                        <CheckBoxFormat
                          label="費用外作業設定"
                          checkboxLabel="この作業を費用外作業にする"
                          checked={editData.isCostless}
                          disabled={isReadOnlyWorkspace || editData.isKey}
                          onChange={e => setEditData({ ...editData, isCostless: e.target.checked })}
                        />
                      </CardBody>

                      <CardBody>
                        <CardTitle className="font-large fw-bold">作業の削除</CardTitle>
                        <CardText className="py-2">
                          作業を削除すると、作業履歴などの情報はすべて失われ、復旧できません。
                        </CardText>
                        <Button
                          outline
                          color="danger"
                          disabled={isReadOnlyWorkspace || selectedScheduleTypeId === NEW_SCHEDULE_TYPE_ID}
                          onClick={() => setOpenDelete(true)}
                        >
                          作業を削除
                        </Button>
                      </CardBody>
                    </div>
                    <CardSubmitFooter
                      onCancel={onCancel}
                      onSubmit={onSubmit}
                      submitDisabled={disabled || unchanged || isReadOnlyWorkspace}
                      cancelDisabled={unchanged}
                      updatedBy={isNewItem ? undefined : scheduleType?.updatedByName}
                      updatedAt={isNewItem ? undefined : scheduleType?.updatedAt}
                    />
                  </>
                ) : (
                  <CardBody className="d-flex align-items-center justify-content-center">
                    <div className="text-center">
                      <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                      <div className="font-middle fw-bold py-4">作業が選択されていません</div>
                      <div>作業を選択して、詳細情報を編集しましょう。</div>
                    </div>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>

          <ScheduleTypeDelete
            isOpen={openDelete}
            workspaceId={workspaceId}
            scheduleTypeId={selectedScheduleTypeId}
            onSuccess={handleDelete}
            onCancel={() => setOpenDelete(false)}
          />
        </div>
      </NavMenu>
    </>
  )
}

export default WorkspaceDetail
