import { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'

import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'

import type { addSpotWorkerData } from 'slices/spotWorkerSlice'
import { addSpotWorkers, selectSpotWorkerStatus } from 'slices/spotWorkerSlice'

import { CustomModal, ItemEdit } from 'components/common'

type Props = {
  isOpen: boolean
  onSuccess: () => void
  onCancel: () => void
  workDate: string
}

export const SpotWorkerCreateDialog = ({ isOpen, onCancel, onSuccess, workDate }: Props) => {
  const dispatch = useDispatch()
  const { availableSpotWorkers, isRequesting, errorMessage } = useSelector(selectSpotWorkerStatus, shallowEqual)
  const [selectedSpotWorkers, setSelectedSpotWorkers] = useState<addSpotWorkerData[]>([])
  const [submitted, setSubmitted] = useState(false)
  const [modelErrorMessage, setModelErrorMessage] = useState<string>()

  const disabled = useMemo(() => selectedSpotWorkers.length === 0, [selectedSpotWorkers])

  const handleApprove = () => {
    dispatch(addSpotWorkers(workDate, selectedSpotWorkers))
    setSelectedSpotWorkers([])
    setSubmitted(true)
  }

  useEffect(() => {
    if (!submitted || isRequesting) {
      return
    }
    if (errorMessage === '') {
      onSuccess()
    } else {
      if (ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
        return
      }
      setModelErrorMessage('スポットメンバーの追加に失敗しました。')
    }
    setSubmitted(false)
  }, [submitted, isRequesting, errorMessage, onSuccess])

  return (
    <CustomModal
      isOpen={isOpen}
      title="スポットメンバーを追加"
      approveDisabled={disabled}
      errorMessage={modelErrorMessage}
      onCancel={onCancel}
      onApprove={handleApprove}
    >
      <div className="d-flex flex-column row-gap-4">
        <div className="fw-bold">スポットメンバー選択</div>
        <div className="mb-4">
          スポットメンバーを選択追加できます。
          <ItemEdit
            items={availableSpotWorkers.map(w => ({ id: w.id, value: w.name }))}
            selectedItems={selectedSpotWorkers.map(data => ({
              id: data.workerId,
              value: data.workerName,
            }))}
            label="スポットメンバーを追加"
            itemName="メンバー"
            onChange={items =>
              setSelectedSpotWorkers(items.map(i => ({ workerId: Number(i.id), workerName: i.value })))
            }
          />
        </div>
      </div>
    </CustomModal>
  )
}
