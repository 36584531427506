import _ from 'lodash'
import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ENABLE_DIALOG_ERROR_STATUS_CODES } from 'api/utils'
import type { WorkResultsType } from 'api/work_results'

import { showError, showSuccess } from 'slices/notificationSlice'
import { selectScheduleTypesStatus, clearErrorMessage } from 'slices/scheduleTypesSlice'
import { selectTenantsStatus } from 'slices/tenantsSlice'
import { getWorkResults, selectWorkResultsStatus, updateWorkResults } from 'slices/workResultsSlice'

import { CustomButton, CustomModal } from 'components/common'

import useBusinessTime from 'hooks/useBusinessTime'
import useDateQuery from 'hooks/useDateQuery'

import ManualInputEditTable from './ManualInputEditTable'

import styles from './ManualInputDialog.module.scss'

type Props = {
  isOpen: boolean
  onCancel: () => void
  onSuccess: () => void
}

const ManualInputDialog: React.FC<Props> = ({ isOpen, onCancel, onSuccess }) => {
  const [submitted, setSubmitted] = React.useState(false)
  const [manualInput, setManualInput] = React.useState<WorkResultsType[]>([])
  const [initManualInput, setInitManualInput] = React.useState<WorkResultsType[]>([])
  const { workspaceId } = useParams<'workspaceId'>()
  const dispatch = useDispatch()
  const { workResults, isRequesting, errorMessage } = useSelector(selectWorkResultsStatus, shallowEqual)
  const { partialScheduleTypes } = useSelector(selectScheduleTypesStatus, shallowEqual)
  const { tenantWithDate } = useSelector(selectTenantsStatus, shallowEqual)
  const { businessHourBlocks, getWorkDate } = useBusinessTime(tenantWithDate)
  const date = getWorkDate(useDateQuery())

  React.useEffect(() => {
    if (!workspaceId) {
      const targetWorkResults = workResults?.workResults || []
      setManualInput(targetWorkResults)
      setInitManualInput(targetWorkResults)
      return
    }

    const scheduleTypeIds = partialScheduleTypes.map(s => s.id)

    const targetWorkResults = workResults?.workResults.filter(wr => scheduleTypeIds.includes(wr.scheduleTypeId)) || []
    setManualInput(targetWorkResults)
    setInitManualInput(targetWorkResults)
  }, [workResults, workspaceId, partialScheduleTypes])

  React.useEffect(() => {
    isOpen && dispatch(getWorkResults(date))
  }, [dispatch, date, isOpen])

  const disabled = React.useMemo(() => _.isEqual(initManualInput, manualInput), [initManualInput, manualInput])
  const handleApprove = () => {
    const newManualInput = manualInput.map(m => {
      const target = initManualInput.find(i => i.scheduleTypeId === m.scheduleTypeId)
      if (!target) {
        return m
      }
      // 差分のない箇所は未入力(-1)として送信する
      const hourlyValue = m.hourlyValue.map((hv, index) => (hv === target.hourlyValue[index] ? -1 : hv))
      return { ...m, hourlyValue }
    })
    setSubmitted(true)
    dispatch(updateWorkResults(date, { workResults: newManualInput }))
  }
  const handleResetButtonClick = () => {
    setManualInput(prev =>
      prev?.map(p => {
        // eslint-disable-next-line no-shadow
        const hourlyValue = p.hourlyValue.map((_, i) => (i < businessHourBlocks.length ? 0 : -1))
        return { ...p, hourlyValue }
      })
    )
  }

  React.useEffect(() => {
    setSubmitted(prev => {
      if (!prev || isRequesting) {
        return prev
      }
      if (errorMessage === '') {
        dispatch(showSuccess())
        onSuccess()
      } else {
        if (!ENABLE_DIALOG_ERROR_STATUS_CODES.includes(errorMessage)) {
          // ENABLE_DIALOG_ERROR_STATUS_CODESのときにはエラーダイアログが出るのでNotificationは出さない
          dispatch(showError())
        }
        dispatch(clearErrorMessage())
      }
      onCancel()
      return false
    })
  }, [submitted, errorMessage, dispatch, onSuccess, onCancel, isRequesting])

  return (
    <CustomModal
      isOpen={isOpen}
      title={`実績入力 : ${date.replace(/-/g, '/')}`}
      errorMessage={''}
      onCancel={onCancel}
      onApprove={handleApprove}
      approveDisabled={disabled}
      onHideNotification={() => {}}
    >
      <div className={styles.container}>
        <div className={styles.stickyLeft}>時間別で実績を入力してください。</div>
        <ManualInputEditTable value={manualInput} onChange={setManualInput} />
        <CustomButton className={styles.stickyLeft} outline onClick={handleResetButtonClick}>
          実績のリセット
        </CustomButton>
      </div>
    </CustomModal>
  )
}

export default ManualInputDialog
