import * as React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardText, CardTitle, Col, Row } from 'reactstrap'

import { Role } from 'api/users'

import { getTenantUserList, getTenantUser, selectUsersStatus } from 'slices/usersSlice'

import AccountTypeChange from 'components/AccountTypeChange/AccountTypeChange'
import { List, VerticalTable } from 'components/common'
import type { VerticalItem } from 'components/common/types'
import { accountTypeName } from 'components/common/utils'

import placeholder from 'images/allEmpty.svg'

import TenantUserDelete from './TenantUserDelete'

import styles from './TenantUsers.module.scss'

type Props = {
  showSuccess: () => void
}

const TenantUsers: React.FC<Props> = ({ showSuccess }) => {
  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [accountType, setAccountType] = React.useState('')
  const [openChange, setOpenChange] = React.useState(false)
  const [openDelete, setOpenDelete] = React.useState(false)
  const [selectedId, setSelectedId] = React.useState('')

  const params = useParams<'tenantId'>()
  const tenantId = Number(params.tenantId)
  const dispatch = useDispatch()

  const { selectedTenantUsers, user } = useSelector(selectUsersStatus, shallowEqual)

  React.useEffect(() => {
    dispatch(getTenantUserList(tenantId))
  }, [dispatch, tenantId])

  React.useEffect(() => {
    if (!selectedId) {
      return
    }

    dispatch(getTenantUser(selectedId, tenantId))
  }, [dispatch, selectedId, tenantId])

  const listItems = React.useMemo(
    () =>
      selectedTenantUsers
        .filter(tenantUser => {
          const userHasTenant = tenantUser.partialUserHasTenants[0]
          return (
            userHasTenant.id === tenantId &&
            (userHasTenant.role === Role.Admin || userHasTenant.role === Role.ProcessAdmin)
          )
        })
        .map(tenantUser => ({ id: tenantUser.userId, title: tenantUser.partialUserHasTenants[0]?.nickname ?? '' })),
    [selectedTenantUsers, tenantId]
  )

  React.useEffect(() => {
    if (listItems.length === 0) {
      return
    }

    setSelectedId(listItems[0].id)
  }, [listItems])

  const selectedUser = React.useMemo(() => {
    if (!user) {
      return
    }

    return {
      ...user,
      updatedAt: user.createdAt,
      updatedByName: user.userHasTenants[0].updatedByName,
    }
  }, [user])

  React.useEffect(() => {
    setName(selectedUser?.userHasTenants[0].nickname || '')
    setEmail(selectedUser?.email || '')
    setAccountType(accountTypeName(selectedUser?.userHasTenants[0].role))
  }, [selectedUser])

  const verticalItems: VerticalItem[] = React.useMemo(() => {
    return [
      { title: '名前', data: name },
      { title: 'メールアドレス', data: email },
      { title: 'アカウントタイプ', data: accountType },
    ]
  }, [name, email, accountType])

  const handleAccountTypeChange = () => {
    // AccountTypeChangeではTenantStateの状態が更新されないためtenantを再取得する
    dispatch(getTenantUserList(tenantId))
    setOpenChange(false)
  }

  const handleDeleteUser = () => {
    setOpenDelete(false)
    showSuccess()
  }

  return (
    <>
      <Row className="g-0">
        <Col md={4}>
          <Card className={`position-sticky ${styles.list}`}>
            {listItems.length > 0 ? (
              <List items={listItems} selectedId={selectedId} onAction={setSelectedId} />
            ) : (
              <CardBody className="text-center">
                <img
                  className={`mx-auto my-auto d-block h-75 w-100 ${styles.placeholderImage}`}
                  src={placeholder}
                  alt=""
                />
                <div className="font-middle fw-bold py-4">テナントに登録されたユーザーがいません</div>
              </CardBody>
            )}
          </Card>
        </Col>
        <Col md={8}>
          <Card>
            {listItems.length > 0 ? (
              <>
                <CardBody>
                  <CardTitle className="font-large fw-bold">ユーザー詳細</CardTitle>
                  <Row className="mx-0 mt-2">
                    <Col className="border-top border-end border-start p-0">
                      <VerticalTable items={verticalItems} titleColumn={3} />
                    </Col>
                  </Row>
                  <Row className="mx-0 mb-2">
                    <Col
                      className="border-start border-end border-bottom text-center text-primary py-3 bg-bgcolor"
                      role="button"
                      onClick={() => setOpenChange(true)}
                    >
                      アカウントタイプの変更
                    </Col>
                  </Row>
                </CardBody>

                <CardBody>
                  <CardTitle className="font-large fw-bold">ユーザーアカウント削除</CardTitle>
                  <CardText className="py-2">
                    ユーザーアカウントを削除すると、アカウント情報などはすべて失われ、復旧できません。
                  </CardText>
                  <Button outline color="danger" className="my-3" onClick={() => setOpenDelete(true)}>
                    このユーザーを削除
                  </Button>
                </CardBody>
              </>
            ) : (
              <CardBody className="text-center">
                <img className={`mx-auto d-block ${styles.placeholderImage}`} src={placeholder} alt="" />
                <div className="font-middle fw-bold py-4">登録ユーザーが選択されていません</div>
                <div>登録ユーザーを選択して、編集しましょう。</div>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      {selectedUser && (
        <AccountTypeChange
          isOpen={openChange}
          userId={selectedUser.userId}
          tenantId={selectedUser.userHasTenants[0].id}
          role={selectedUser.userHasTenants[0].role}
          onSuccess={handleAccountTypeChange}
          onCancel={() => setOpenChange(false)}
        />
      )}

      <TenantUserDelete
        isOpen={openDelete}
        tenantId={tenantId}
        userId={selectedId}
        onSuccess={handleDeleteUser}
        onCancel={() => setOpenDelete(false)}
      />
    </>
  )
}

export default TenantUsers
