import dayjs from 'dayjs'
import { isEqual } from 'es-toolkit'
import { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import { getWorkspaceSummary, selectDashboardStatus } from 'slices/dashboardSlice'
import { selectSessionStatus } from 'slices/sessionSlice'

import { NO_GROUP_ID } from 'components/TeamAssignment/utils'
import { BadgeLabel, IndicatorProgressBar } from 'components/common'

import useBusinessTime from 'hooks/useBusinessTime'

import { TeamProgressTable } from './TeamProgressTable'

import styles from './TeamProgress.module.scss'

// 現時刻までの計画に対する実績が何%以上ずれたら赤文字で表示するか
const WARNING_RATE = 10

type PlanRecordCount = {
  planCount: number
  recordCount: number
}
const initPlanRecordCount = {
  planCount: 0,
  recordCount: 0,
}
const appendPlanRecordCount = (counts: PlanRecordCount[]) => {
  return counts.reduce(
    (acc, cur) => ({
      planCount: acc.planCount + cur.planCount,
      recordCount: acc.recordCount + cur.recordCount,
    }),
    initPlanRecordCount
  )
}

export const TeamProgress = () => {
  const {
    team: { workspaceId, groupId, groupName, groupColor },
  } = useSelector(selectSessionStatus, shallowEqual)
  const { workspaceSummary } = useSelector(selectDashboardStatus, shallowEqual)

  const dispatch = useDispatch()

  const { getWorkDate } = useBusinessTime()

  useEffect(() => {
    dispatch(getWorkspaceSummary(workspaceId, getWorkDate(dayjs().format('YYYY-MM-DD'))))
  }, [dispatch, workspaceId, getWorkDate])

  const workspaceSummaryData = workspaceSummary?.workspaceData

  const { diffPlanCount, achievementRate, planRate, warning } = useMemo(() => {
    // 表示中のグループのメンバーデータを取得
    const workerData = workspaceSummaryData?.groups.find(d =>
      groupId === NO_GROUP_ID ? d.groupId === null : d.groupId === groupId
    )?.workers

    // 1日の計画値合計を計算
    const totalPlanCount = workerData
      ?.flatMap(w => w.hourlyWorkerData)
      .flatMap(hwd => hwd.data)
      .map(d => d.planCount)
      .reduce((acc: number, cur) => acc + (cur || 0), 0)

    // 現時刻までの実績値と計画値を取得
    const record = workerData?.reduce((acc, cur) => {
      const count = cur.hourlyWorkerData.map(hourly => {
        const hourlyCount = hourly.data
          .filter(d => dayjs(d.time).isBefore(dayjs().startOf('hour').add(1, 'hour')))
          .map(d => ({ planCount: d.planCount, recordCount: d.recordCount }) as PlanRecordCount)
        return appendPlanRecordCount(hourlyCount)
      })
      const workerCount = appendPlanRecordCount(count)
      return {
        planCount: acc.planCount + workerCount.planCount,
        recordCount: acc.recordCount + workerCount.recordCount,
      }
    }, initPlanRecordCount)

    if (record && !isEqual(record, initPlanRecordCount)) {
      // 達成率は実績/1日の計画 計画との差は現時刻までの実績-計画で算出する
      return {
        diffPlanCount: Math.floor(record.recordCount - record.planCount),
        achievementRate: totalPlanCount ? Math.floor((record.recordCount / totalPlanCount) * 100) : 0,
        planRate: totalPlanCount ? (record.planCount / totalPlanCount) * 100 : 0,
        warning: record.planCount
          ? Math.abs((record.recordCount / record.planCount) * 100 - 100) >= WARNING_RATE
          : true,
      }
    }
    return { diffPlanCount: 0, achievementRate: 0, planRate: 0, warning: true }
  }, [groupId, workspaceSummaryData])

  const lastImportedAt = useMemo(() => {
    if (!workspaceSummaryData) {
      return undefined
    }
    const latestLastImportedAt = workspaceSummaryData.groups
      .filter(d => d.groupId || groupId === NO_GROUP_ID)
      .flatMap(w => w.workers)
      .flatMap(w => w.hourlyWorkerData)
      .map(d => d.lastImportedAt)
      .reduce((acc, cur) => (acc > cur ? acc : cur), '')

    return latestLastImportedAt ? dayjs(latestLastImportedAt).format('YYYY/MM/DD HH:mm:ss') : undefined
  }, [workspaceSummaryData, groupId])

  return (
    <div className={`${styles.container} d-flex flex-column`}>
      <Card className="m-4">
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex w-25">
              <BadgeLabel label={groupName} />
            </div>

            <div className="d-flex align-items-center">
              <div className="d-flex flex-column">
                <span className={`${styles.label} text-muted`}>達成率</span>
                <span className={`${styles.value} fw-bold`}>{achievementRate}%</span>
              </div>

              <div className="d-flex flex-column ms-4">
                <span className={`${styles.label} text-muted`}>計画との差</span>
                <span className={`${styles.value} ${warning ? 'text-danger' : ''} fw-bold`}>
                  {diffPlanCount.toLocaleString()}
                </span>
              </div>

              <div className="d-flex flex-column ms-5">
                <div className={`${styles.progressBar} mt-3 mx-3`}>
                  <IndicatorProgressBar achievementRate={achievementRate} planRate={planRate} warning={warning} />
                </div>

                <div className="d-flex text-muted align-self-end">
                  {lastImportedAt && <i className="icf-updated align-self-center pe-1" />}
                  <small>{lastImportedAt}</small>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <div className={`${styles.tableWrapper} mx-4 mb-4`}>
        {workspaceSummaryData && (
          <TeamProgressTable
            groupId={groupId}
            groupName={groupName}
            workspaceSummaryData={workspaceSummaryData}
            groupColor={groupColor}
          />
        )}
      </div>
    </div>
  )
}
