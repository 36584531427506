import { useState, useEffect, useMemo, useRef, forwardRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import { selectPlansStatus } from 'slices/plansSlice'
import { selectSkillsStatus } from 'slices/skillsSlice'

import { WorkerTypesLabel } from 'components/common/utils'

import { useElementSize } from 'hooks/useElementSize'

import styles from './PerformanceIndices.module.scss'

const Skills = ({ items, showMore, width }: { items: string[]; showMore: boolean; width: number }) => {
  return (
    items.length > 0 && (
      <div className={`${showMore ? styles.skillCompact : ''} overflow-hidden mb-1`} style={{ width: `${width}px` }}>
        {items?.map((name, index) => (
          <div
            key={index}
            className={`${styles.badge} d-inline-block rounded-pill bg-light-gray mw-100 text-truncate me-1 px-2`}
            title={name}
          >
            {name}
          </div>
        ))}
      </div>
    )
  )
}

type ProductivitiesProps = {
  items: {
    value: string
    unit: string
    scheduleTypeName: string
  }[]
  showMore: boolean
}

const Productivities = forwardRef<HTMLDivElement, ProductivitiesProps>(({ items, showMore }, ref) => {
  const displayItems = useMemo(() => (showMore ? items.slice(0, 3) : items), [items, showMore])

  return (
    displayItems.length > 0 && (
      <div className="d-flex flex-column gap-1" ref={ref}>
        {displayItems?.map((p, index) => (
          <div key={index} className="d-flex align-items-center">
            <div className={`flex-shrink-0 text-truncate ${styles.scheduleTypeName}`} title={p.scheduleTypeName}>
              {p.scheduleTypeName}
            </div>
            ：
            <div className="text-end w-100">
              {p.value} {p.unit}時間
            </div>
          </div>
        ))}
      </div>
    )
  )
})
Productivities.displayName = 'Productivities'

type Props = {
  workerId: number
  onCloseButtonClick: () => void
}

export const PerformanceIndicesPopover = ({ workerId, onCloseButtonClick }: Props) => {
  const { plans } = useSelector(selectPlansStatus, shallowEqual)
  const { skills } = useSelector(selectSkillsStatus, shallowEqual)

  const [showMore, setShowMore] = useState(true)

  const productivityRef = useRef<HTMLDivElement>(null)
  const productivitySize = useElementSize(productivityRef)

  // ポップアップ対象ワーカー
  const selectedWorker = useMemo(() => {
    if (!plans) {
      return
    }
    return plans?.groups.flatMap(group => group.workersPlan).find(worker => worker.workerId === workerId) || null
  }, [plans, workerId])

  //ポップアップ対象ワーカーのスキルリスト
  const selectedWorkerSkillNames = useMemo(() => {
    if (!selectedWorker) {
      return []
    }

    return selectedWorker.skillIds
      .map(skillId => {
        const skill = skills.find(s => s.id === skillId)
        return skill ? skill.name : null
      })
      .filter(Boolean) as string[]
  }, [selectedWorker, skills])

  // ポップアップ対象ワーカーの人時生産性
  const selectedWorkerProductivities = useMemo(() => {
    if (!selectedWorker) {
      return []
    }

    return (
      plans?.partialScheduleTypes
        .filter(scheduleType => scheduleType.connectionType !== 0)
        .map(scheduleType => {
          const matchingProductivity = selectedWorker.partialHourlyProductivities.find(
            productivity => productivity.scheduleTypeId === scheduleType.id
          )

          return {
            value: (matchingProductivity?.value || scheduleType.defaultProductivity)?.toString() || '',
            unit: scheduleType.unit || '',
            scheduleTypeName: scheduleType.name,
          }
        }) || []
    )
  }, [selectedWorker, plans])

  useEffect(() => {
    if (selectedWorkerSkillNames.length === 0 && selectedWorkerProductivities.length === 0) {
      setShowMore(false)
    }
  }, [selectedWorkerSkillNames, selectedWorkerProductivities])

  const workerTypeLabel = useMemo(
    () => WorkerTypesLabel.find(item => item.key === selectedWorker?.workerType)?.value,
    [selectedWorker?.workerType]
  )

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between mb-2">
          <div style={{ width: productivitySize.width }}>
            <div className="text-muted">{workerTypeLabel}</div>
            <div className="fw-bold text-truncate" title={selectedWorker?.workerName}>
              {selectedWorker?.workerName}
            </div>
          </div>
          <i className="icf-close font-large mt-1" role="button" onClick={onCloseButtonClick} />
        </div>
        <Skills items={selectedWorkerSkillNames} showMore={showMore} width={productivitySize.width} />
        <Productivities items={selectedWorkerProductivities} ref={productivityRef} showMore={showMore} />

        {showMore && (
          <div className="text-primary text-center mt-2" role="button" onClick={() => setShowMore(false)}>
            もっと見る
          </div>
        )}
      </CardBody>
    </Card>
  )
}
