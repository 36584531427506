import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { DropdownItem, Input } from 'reactstrap'

import { DropdownList, TimeSelect } from 'components/common'

import useBusinessTime from 'hooks/useBusinessTime'

import { UNSELECTED_TIME_LABEL } from './utils'

import styles from './TableInputForms.module.scss'

import type { ChangeEvent } from 'react'

type Item = {
  key?: string | number
  value: string
}

type SelectDropdownProps = {
  content: React.ReactNode
  items: Item[]
  onSelect: (item: Item) => void
  containerRef?: HTMLDivElement
  isContainer?: boolean
  disabled?: boolean
  className?: string
}

export const SelectDropdown = ({
  content,
  items,
  onSelect,
  containerRef,
  isContainer,
  disabled = false,
  className,
}: SelectDropdownProps) => {
  const [activeItem, setActiveItem] = useState<number | null>(null)
  const [isOpen, setOpen] = useState(false)

  const handleDropdownItemClick = (item: Item) => {
    onSelect(item)
    clean()
  }

  const clean = useCallback(() => {
    setActiveItem(null)
  }, [])

  return (
    <DropdownList
      open={!disabled && isOpen}
      setOpen={() => setOpen(!isOpen)}
      content={content}
      direction="down"
      dropdownClassName={className}
      isContainer={isContainer}
      containerRef={isContainer ? containerRef || document.body : undefined}
    >
      <div className={styles.searchList}>
        {items.map((item, index) => {
          return (
            <DropdownItem
              key={index.toString()}
              className={`${styles.item} ${index === activeItem && styles.activeItem} text-truncate`}
              onClick={() => handleDropdownItemClick(item)}
              onMouseOver={() => setActiveItem(index)}
            >
              {item.value}
            </DropdownItem>
          )
        })}
      </div>
    </DropdownList>
  )
}

type SelectTimeFormProps = {
  startTime: string | null
  endTime: string | null
  onStartTimeChange: (hour: string, minute: string) => void
  onEndTimeChange: (hour: string, minute: string) => void
  isPortal?: boolean
  portalTarget?: HTMLDivElement | null
  disabled?: boolean
}

const UNSELECTED_HOUR_MINUTE_LABEL = '--'

export const SelectTimeForm = ({
  startTime,
  endTime,
  onStartTimeChange,
  onEndTimeChange,
  isPortal,
  portalTarget,
  disabled = false,
}: SelectTimeFormProps) => {
  const [isEditStartTime, setIsEditStartTime] = useState(false)
  const [isEditEndTime, setIsEditEndTime] = useState(false)

  const { timeRange } = useBusinessTime()

  const pointerEvents = disabled ? 'pe-none' : ''

  const handleStartTimeChange = (hour: string, minute: string) => {
    onStartTimeChange(hour, minute)
    setIsEditStartTime(false)
  }
  const handleEndTimeChange = (hour: string, minute: string) => {
    onEndTimeChange(hour, minute)
    setIsEditEndTime(false)
  }

  const handleStartTimeBlur = () => {
    setIsEditStartTime(false)
  }
  const handleEndTimeBlur = () => {
    setIsEditEndTime(false)
  }

  return (
    <Fragment>
      {isEditStartTime ? (
        <TimeSelect
          onBlur={handleStartTimeBlur}
          menuIsOpen={isEditStartTime}
          hour={startTime ? startTime.split(':')[0] : '00'}
          minute={startTime ? startTime.split(':')[1] : '00'}
          label=""
          onChange={handleStartTimeChange}
          portalTarget={isPortal ? portalTarget || document.body : undefined}
          additionalOptions={[
            {
              label: UNSELECTED_TIME_LABEL,
              value: { hour: UNSELECTED_HOUR_MINUTE_LABEL, minute: UNSELECTED_HOUR_MINUTE_LABEL },
            },
          ]}
          range={timeRange.start}
        />
      ) : (
        startTime || UNSELECTED_TIME_LABEL
      )}
      {!isEditStartTime && (
        <i
          className={`icf-clock font-large ps-1 ${pointerEvents}`}
          onClick={() => setIsEditStartTime(!isEditStartTime)}
        />
      )}
      <div className="p-1">{'〜'}</div>
      {isEditEndTime ? (
        <TimeSelect
          onBlur={handleEndTimeBlur}
          menuIsOpen={isEditEndTime}
          hour={endTime ? endTime.split(':')[0] : '00'}
          minute={endTime ? endTime.split(':')[1] : '00'}
          label=""
          onChange={handleEndTimeChange}
          portalTarget={isPortal ? portalTarget || document.body : undefined}
          additionalOptions={[
            {
              label: UNSELECTED_TIME_LABEL,
              value: { hour: UNSELECTED_HOUR_MINUTE_LABEL, minute: UNSELECTED_HOUR_MINUTE_LABEL },
            },
          ]}
          range={timeRange.end}
        />
      ) : (
        endTime || UNSELECTED_TIME_LABEL
      )}
      {!isEditEndTime && (
        <i className={`icf-clock font-large ps-1 ${pointerEvents}`} onClick={() => setIsEditEndTime(!isEditEndTime)} />
      )}
    </Fragment>
  )
}

export type DropdownWithSearchBoxItemProps = { key?: number; value: string }

type DropDownWithSearchBoxProps = {
  selectedItem: string
  items: DropdownWithSearchBoxItemProps[]
  placeholder?: string
  icon: string
  textColor: string
  onSelect: (item: DropdownWithSearchBoxItemProps) => void
  onClick?: (isOpen: boolean) => void
  containerRef?: HTMLDivElement
  disabled?: boolean
  invisibleSearchBox?: boolean
}
export const DropDownWithSearchBox = ({
  items,
  selectedItem,
  placeholder,
  icon,
  textColor,
  onSelect,
  onClick,
  containerRef,
  disabled = false,
  invisibleSearchBox = false,
}: DropDownWithSearchBoxProps) => {
  const [value, setValue] = useState('')
  const [isOpen, setOpen] = useState(false)

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setValue(ev.target.value)
  }
  const handleDropdownItemClick = (item: { value: string }) => {
    onSelect(item)
  }
  const itemList = useMemo(() => items.filter(item => item.value.includes(value)), [items, value])

  return (
    <>
      <DropdownList
        open={!disabled && isOpen}
        setOpen={() => setOpen(!isOpen)}
        content={
          <DropdownItem
            disabled={disabled}
            className={`${textColor} d-flex align-items-center column-gap-2`}
            onClick={() => onClick?.(isOpen)}
          >
            <div className={`${styles.inputSearchBox} text-truncate`}>{selectedItem}</div>
            <i className={`${icon} font-large text-muted`} />
          </DropdownItem>
        }
        direction="down"
        dropdownClassName={invisibleSearchBox ? styles.inputSelectDropDown : ''}
        containerRef={containerRef || document.body}
        isContainer
      >
        <div className="d-flex flex-column justify-content-center">
          {invisibleSearchBox && (
            <div className="mx-2">
              <Input
                value={value}
                type="search"
                placeholder={placeholder}
                className="mx-auto my-2"
                onChange={handleChange}
              />
            </div>
          )}
          <div className={styles.searchList}>
            {itemList.map((item, index) => {
              return (
                <DropdownItem key={index} onClick={() => handleDropdownItemClick(item)}>
                  {item.value}
                </DropdownItem>
              )
            })}
          </div>
        </div>
      </DropdownList>
    </>
  )
}

type EditTextFormProps = {
  value: string
  defaultValue: string
  onChange: (value: string) => void
  disabled?: boolean
}

const UNENTERED_TEXT_LABEL = '-'
export const EditTextForm = ({ value, defaultValue, onChange, disabled = false }: EditTextFormProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(value)

  // 更新時に入力内容と異なる結果を取得した際に、その内容で更新する
  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleValueChange = () => {
    setIsEdit(!isEdit)
    onChange(inputValue)
  }

  return (
    <>
      {isEdit ? (
        <>
          <Input
            autoFocus
            onBlur={handleValueChange}
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            className="m-0"
            defaultValue={defaultValue}
            maxLength={100}
          />
          <i className="icf-save font-large" role="button" onClick={handleValueChange} />
        </>
      ) : (
        <>
          <div className={`${disabled ? 'text-muted opacity-50' : ''} ${styles.editTextForm} text-truncate`}>
            {value || UNENTERED_TEXT_LABEL}
          </div>
          <i
            className={`icf-edit ${disabled ? 'opacity-50 pe-none' : ''} font-large`}
            role="button"
            onClick={() => setIsEdit(prev => !prev)}
          />
        </>
      )}
    </>
  )
}
