import dayjs from 'dayjs'
import _ from 'lodash'
import * as React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CardBody, Row, Col } from 'reactstrap'

import type { WorkspaceSummaryData, HourlyWorkerRow, WorkspaceSummaryGroup } from 'api/dashboard'

import { selectTenantsStatus } from 'slices/tenantsSlice'

import { List, BadgeButton, Chart } from 'components/common'
import type { BadgeItem, Series } from 'components/common/types'
import { append, createLineChartOptions, isSupportedWorkerGroup } from 'components/common/utils'

import useBusinessTime from 'hooks/useBusinessTime'
import { useChart } from 'hooks/useChart'
import useDateQuery from 'hooks/useDateQuery'

import {
  createXAxis,
  colorTypeToCode,
  NULL_GROUP_ID,
  NULL_GROUP_NAME,
  LIST_WORKSPACE_ID,
  SUPPORT_WORKER_GROUP_PREFIX,
} from './utils'

import styles from './GroupPerformanceGraph.module.scss'

type GroupPerformanceBadgeItem = BadgeItem & {
  unit: string
}

export type Props = {
  workspaceId: number
  groupId: number | string | undefined
  workspaceSummaryData: WorkspaceSummaryData | undefined
}

const GroupPerformanceGraph: React.FC<Props> = ({ workspaceId, groupId, workspaceSummaryData }) => {
  // apiはTotalSummaryで呼び出す
  const { tenantWithDate } = useSelector(selectTenantsStatus, shallowEqual)
  const { getTimeOver24h, businessStartTime, businessEndTime } = useBusinessTime(tenantWithDate)
  const [selectedId, setSelectedId] = React.useState<number | string>(LIST_WORKSPACE_ID)
  const [selectedBadgeKeys, setSelectedBadgeKeys] = React.useState<number[]>([])
  const navigate = useNavigate()
  const date = useDateQuery()
  const { tooltipFormatter } = useChart()

  const isSelectedGroup = React.useCallback((group: WorkspaceSummaryGroup, targetId: number | string | null) => {
    return (
      group.groupId === targetId ||
      (isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName) &&
        targetId === `${SUPPORT_WORKER_GROUP_PREFIX}${group.supportedWorkspaceId}`)
    )
  }, [])

  React.useEffect(() => {
    setSelectedId(groupId === undefined ? LIST_WORKSPACE_ID : groupId === NULL_GROUP_ID ? NULL_GROUP_ID : groupId)
  }, [groupId])

  const listItems = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return []
    }
    const groupItems = workspaceSummaryData.groups.map(group => {
      if (isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName)) {
        return {
          id: `${SUPPORT_WORKER_GROUP_PREFIX}${group.supportedWorkspaceId}`,
          title: group.supportedWorkspaceName ?? '',
        }
      }

      return {
        id: group.groupId ?? NULL_GROUP_ID,
        title: group.groupName ?? NULL_GROUP_NAME,
      }
    })
    return [{ id: LIST_WORKSPACE_ID, title: workspaceSummaryData.workspaceName }, ...groupItems]
  }, [workspaceSummaryData])

  const handleListAction = (id: number | string) => {
    if (id === LIST_WORKSPACE_ID) {
      navigate(`/dashboard/${workspaceId}/performance-graph/workspace?date=${date}`)
    } else {
      navigate(`/dashboard/${workspaceId}/performance-graph/groups/${id}?date=${date}`)
    }
  }

  const graphBadges = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return []
    }

    if (selectedId === LIST_WORKSPACE_ID) {
      return workspaceSummaryData.targets.map<GroupPerformanceBadgeItem>(target => ({
        color: target.scheduleTypeColor,
        key: target.scheduleTypeId,
        label: target.scheduleTypeName,
        unit: target.unit,
      }))
    }
    const targetId = selectedId === NULL_GROUP_ID ? null : selectedId
    const items = workspaceSummaryData.groups
      .filter(group => isSelectedGroup(group, targetId))
      .flatMap(group =>
        group.workers.flatMap(worker =>
          worker.hourlyWorkerData.map<GroupPerformanceBadgeItem>(data => ({
            color: data.scheduleTypeColor,
            key: data.scheduleTypeId,
            label: data.scheduleTypeName,
            unit: data.unit,
          }))
        )
      )
    return _.uniqBy(items, 'key')
  }, [isSelectedGroup, selectedId, workspaceSummaryData])

  React.useEffect(() => {
    // graphBadgesが更新されたら初回はbadgeを全て選択状態にする
    setSelectedBadgeKeys(graphBadges.map(graphBadge => graphBadge.key))
  }, [graphBadges])

  const selectedGroupItem = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return []
    }

    if (selectedId === LIST_WORKSPACE_ID) {
      return workspaceSummaryData.groups
    }

    if (selectedId === NULL_GROUP_ID) {
      return workspaceSummaryData.groups.filter(
        group =>
          !isSupportedWorkerGroup(group.supportedWorkspaceId, group.supportedWorkspaceName) && group.groupId === null
      )
    }

    return workspaceSummaryData.groups.filter(group => isSelectedGroup(group, selectedId))
  }, [workspaceSummaryData, selectedId, isSelectedGroup])

  const chartOptions = React.useMemo(() => {
    if (!workspaceSummaryData) {
      return {}
    }

    const selectedBadges = graphBadges.filter(badge => selectedBadgeKeys.findIndex(key => key === badge.key) !== -1)
    const xAxisData = createXAxis(businessStartTime, businessEndTime, false, date)

    const yAxis: Series[] = []
    selectedBadges.forEach(graphBadge => {
      const colorCode = colorTypeToCode(graphBadge.color)

      const hourlyWorkerRows = selectedGroupItem.flatMap(group =>
        group.workers.flatMap(worker =>
          worker.hourlyWorkerData
            .filter(hourlyData => hourlyData.scheduleTypeId === graphBadge.key)
            .flatMap(hourlyData => hourlyData.data.map(data => data))
        )
      )

      const summaryRows = hourlyWorkerRows.reduce<HourlyWorkerRow[]>((acc, cur) => {
        const target = acc.find(row => row.time === cur.time)
        if (!target) {
          return acc.concat([cur])
        }
        const newRow = { ...target }
        newRow.planCount = append(newRow.planCount, cur.planCount)
        newRow.recordCount = append(newRow.recordCount, cur.recordCount)
        return acc.map(row => (row.time === cur.time ? newRow : row))
      }, [])

      // areaの上にlineを表示するためにareaを先にしておく
      const recordData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        const target = summaryRows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
        return target ? target.recordCount : null
      })
      yAxis.push({
        type: 'area',
        color: colorCode,
        data: recordData,
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
        },
      })

      const planData = xAxisData.map(time => {
        // パフォーマンス改善のために moment でなく new Date を使う
        const target = summaryRows.find(row => new Date(row.time).getTime() === new Date(time).getTime())
        return target ? target.planCount : null
      })
      const firstDataIndex = planData.findIndex(d => d !== null)
      yAxis.push({
        type: 'line',
        color: colorCode,
        data: planData,
        name: graphBadge.label,
        custom: {
          unit: graphBadge.unit ?? '',
          firstDataIndex,
        },
      })
    })

    const options = createLineChartOptions({
      xAxis: {
        data: xAxisData,
      },
      yAxis,
    })
    ;(options.xAxis as Highcharts.XAxisOptions).labels!.formatter = function () {
      if (this.isFirst) {
        return dayjs(this.value).format('HH:mm')
      }
      return getTimeOver24h(dayjs(this.value).format('HH:mm'))
    }
    _.merge<Highcharts.Options, Highcharts.Options>(options, {
      tooltip: {
        formatter: tooltipFormatter,
      },
      xAxis: {
        labels: {
          step: 2,
        },
        tickInterval: 1,
      },
    })
    return options
  }, [
    workspaceSummaryData,
    graphBadges,
    businessStartTime,
    businessEndTime,
    date,
    tooltipFormatter,
    selectedBadgeKeys,
    selectedGroupItem,
    getTimeOver24h,
  ])

  return (
    <Row>
      <Col md={4}>
        <div className={styles.list}>
          <List items={listItems} selectedId={selectedId} onAction={handleListAction} />
        </div>
      </Col>
      <Col md={8}>
        <Chart options={chartOptions} />

        <CardBody className="d-flex row my-2">
          <BadgeButton
            items={graphBadges}
            selected={selectedBadgeKeys}
            onChange={(list: number[]) => setSelectedBadgeKeys(list)}
          />
        </CardBody>
      </Col>
    </Row>
  )
}

export default GroupPerformanceGraph
